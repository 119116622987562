import { httpRequest } from "./http";

@httpRequest
class OtherService {
  queryVersion() {
    return this.$httpRequest({ url: `/resource/config/about.json?${Date.now()}` });
  }
  queryLink() {
    return this.$httpRequest({ url: `/resource/config/link.json?${Date.now()}` });
  }
  systemUpdate() {
    return this.$httpRequest({ url: `/resource/config/systemUpdate.json?${Date.now()}` });
  }
  appUpload() {
    return this.$httpRequest({ url: `/resource/config/appUpload.json?${Date.now()}` });
  }
  pdfUpload() {
    return this.$httpRequest({ url: `/resource/config/uploadPdf.json?${Date.now()}` });
  }
}

export default new OtherService();
