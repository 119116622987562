import config from '../config';
const { api, version } = config;

export default {
  controlRealAlarmModule: {
    text: '事件提醒',
    code: 109700
  },
  enterControlRealAlarmModule: {
    text: '进入事件提醒界面',
    code: 109799,
    parent: 109700,
    moduleName: 'controlRealAlarm'
  },
  // 无帽进入后厨
  noCapListModule: {
    text: '无帽进入后厨历史提醒',
    code: 111200
  },
  enterNoCapListModule: {
    text: '进入无帽进入后厨历史提醒界面',
    code: 111299,
    parent: 111200,
    moduleName: 'noCapList'
  },
  noCapTaskModule: {
    text: '无帽进入后厨任务管理',
    code: 111300
  },
  enterNoCapTaskModule: {
    text: '进入无帽进入后厨布控任务管理界面',
    code: 111399,
    parent: 111300,
    moduleName: 'noCapTask'
  },
  // 撑伞经营
  umbrellaListModule: {
    text: '撑伞经营历史提醒',
    code: 111400
  },
  enterUmbrellaListModule: {
    text: '进入撑伞经营历史提醒界面',
    code: 111499,
    parent: 111400,
    moduleName: 'umbrellaList'
  },
  noUmbrellaTaskModule: {
    text: '撑伞经营任务管理',
    code: 111500
  },
  enterUmbrellaTaskModule: {
    text: '进入撑伞经营布控任务管理界面',
    code: 111599,
    parent: 111500,
    moduleName: 'umbrellaTask'
  },
  // 非机动乱停乱放
  nonVehicleParkingListModule: {
    text: '非机动乱停乱放历史提醒',
    code: 111600
  },
  enterNonVehicleParkingListModule: {
    text: '进入非机动乱停乱放历史提醒界面',
    code: 111699,
    parent: 111600,
    moduleName: 'nonVehicleParkingList'
  },
  nonVehicleParkingTaskModule: {
    text: '非机动乱停乱放任务管理',
    code: 111700
  },
  enterNonVehicleParkingTaskModule: {
    text: '进入非机动乱停乱放布控任务管理界面',
    code: 111799,
    parent: 111700,
    moduleName: 'nonVehicleParkingTask'
  },
  // 沿街晾晒
  dryingStreetListModule: {
    text: '沿街晾晒历史提醒',
    code: 111800
  },
  enterDryingStreetListModule: {
    text: '进入非机动乱停乱放历史提醒界面',
    code: 111899,
    parent: 111800,
    moduleName: 'dryingStreetList'
  },
  nonDryingStreetTaskModule: {
    text: '沿街晾晒任务管理',
    code: 111900
  },
  enterDryingStreetTaskModule: {
    text: '进入沿街晾晒布控任务管理界面',
    code: 111999,
    parent: 111900,
    moduleName: 'dryingStreetTask'
  },
  // 沿街小贩
  hawkerStreetListModule: {
    text: '沿街小贩历史提醒',
    code: 112000
  },
  enterHawkerStreetListModule: {
    text: '进入沿街小贩历史提醒界面',
    code: 112099,
    parent: 112000,
    moduleName: 'hawkerStreetList'
  },
  hawkerStreetTaskModule: {
    text: '沿街小贩任务管理',
    code: 112100
  },
  enterHawkerStreetTaskModule: {
    text: '进入沿街小贩布控任务管理界面',
    code: 112199,
    parent: 112100,
    moduleName: 'hawkerStreetTask'
  },
  // 占道经营
  chamdowOperationsListModule: {
    text: '占道经营历史提醒',
    code: 112200
  },
  enterChamdowOperationsListModule: {
    text: '进入占道经营历史提醒界面',
    code: 112299,
    parent: 112200,
    moduleName: 'chamdowOperationsList'
  },
  chamdowOperationsTaskModule: {
    text: '占道经营任务管理',
    code: 112100
  },
  enterChamdowOperationsTaskModule: {
    text: '进入占道经营布控任务管理界面',
    code: 112300,
    parent: 112300,
    moduleName: 'chamdowOperationsTask'
  },
  // 占道广告牌
  chamdowBillboardListModule: {
    text: '占道广告牌历史提醒',
    code: 112200
  },
  enterChamdowBillboardListModule: {
    text: '进入占道广告牌历史提醒界面',
    code: 112499,
    parent: 112400,
    moduleName: 'chamdowBillboardList'
  },
  chamdowBillboardTaskModule: {
    text: '占道广告牌任务管理',
    code: 112500
  },
  enterChamdowBillboardTaskModule: {
    text: '进入占道经营布控任务管理界面',
    code: 112599,
    parent: 112500,
    moduleName: 'chamdowBillboardTask'
  },
  // 垃圾暴露
  garbageExposedListModule: {
    text: '垃圾暴露历史提醒',
    code: 112600
  },
  enterGarbageExposedListModule: {
    text: '进入垃圾暴露历史提醒界面',
    code: 112699,
    parent: 112600,
    moduleName: 'garbageExposedList'
  },
  garbageExposedTaskModule: {
    text: '垃圾暴露任务管理',
    code: 112700
  },
  enterGarbageExposedTaskModule: {
    text: '进入垃圾暴露布控任务管理界面',
    code: 112799,
    parent: 112700,
    moduleName: 'garbageExposedTask'
  },
  // 垃圾袋乱堆
  garbageDumpingListModule: {
    text: '垃圾袋乱堆历史提醒',
    code: 112800
  },
  enterGarbageDumpingListModule: {
    text: '进入垃圾袋乱堆历史提醒界面',
    code: 112899,
    parent: 112800,
    moduleName: 'garbageDumpingList'
  },
  garbageDumpingTaskModule: {
    text: '垃圾袋乱堆任务管理',
    code: 112900
  },
  enterGarbageDumpingTaskModule: {
    text: '进入垃圾袋乱堆布控任务管理界面',
    code: 112999,
    parent: 112900,
    moduleName: 'garbageDumpingTask'
  },
  // 垃圾桶满溢
  garbageOverflowListModule: {
    text: '垃圾桶满溢历史提醒',
    code: 113000
  },
  enterGarbageOverflowListModule: {
    text: '进入垃圾桶满溢历史提醒界面',
    code: 113099,
    parent: 113000,
    moduleName: 'garbageOverflowList'
  },
  garbageOverflowTaskModule: {
    text: '垃圾桶满溢任务管理',
    code: 113100
  },
  enterGarbageOverflowTaskModule: {
    text: '进入垃圾桶满溢布控任务管理界面',
    code: 113199,
    parent: 113100,
    moduleName: 'garbageOverflowTask'
  },
  // 乱堆物料
  materialsDumpingListModule: {
    text: '乱堆物料历史提醒',
    code: 113200
  },
  enterMaterialsDumpingListModule: {
    text: '进入垃圾桶满溢历史提醒界面',
    code: 113299,
    parent: 113200,
    moduleName: 'materialsDumpingList'
  },
  materialsDumpingTaskModule: {
    text: '乱堆物料任务管理',
    code: 113300
  },
  enterMaterialsDumpingTaskModule: {
    text: '进入乱堆物料布控任务管理界面',
    code: 113399,
    parent: 113300,
    moduleName: 'materialsDumpingTask'
  },
  queryAlarms: {
    value: `${api}prevention/result/${version}/queryAlarms`,
    label: '查询防控事件告警列表',
    actionName: 'queryAlarms'
  },
  queryTasks: {
    value: `${api}prevention/task/${version}/queryTasks`,
    label: '查询防控任务列表',
    actionName: 'queryTasks'
  },
  dealAlarm: {
    value: `${api}prevention/result/${version}/dealAlarm`,
    label: '处理防控事件告警',
    actionName: 'dealAlarm',
    logInfo: [
      {
        type: 'realAlarmDetail',
        text: '处理提醒信息',
        code: 109702,
        parent: 109700
      },
      {
        type: 'noCapDetail',
        text: '处理提醒信息',
        code: 111202,
        parent: 111200,
        description: '标记无帽进入后厨布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'umbrellaDetail',
        text: '处理提醒信息',
        code: 111402,
        parent: 111400,
        description: '标记撑伞经营布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'nonVehicleParkingDetail',
        text: '处理提醒信息',
        code: 111602,
        parent: 111600,
        description: '标记非机动乱停乱放布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'dryingStreetDetail',
        text: '处理提醒信息',
        code: 111802,
        parent: 111800,
        description: '标记沿街晾晒布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'hawkerStreetDetail',
        text: '处理提醒信息',
        code: 112002,
        parent: 112000,
        description: '标记沿街小贩布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'chamdowOperationsDetail',
        text: '处理提醒信息',
        code: 112202,
        parent: 112200,
        description: '标记占道经营布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'chamdowBillboardDetail',
        text: '处理提醒信息',
        code: 112402,
        parent: 112400,
        description: '标记占道广告牌布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'garbageExposedDetail',
        text: '处理提醒信息',
        code: 112602,
        parent: 112600,
        description: '标记垃圾暴露布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'garbageDumpingDetail',
        text: '处理提醒信息',
        code: 112802,
        parent: 112800,
        description: '标记垃圾袋乱堆布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'garbageOverflowDetail',
        text: '处理提醒信息',
        code: 113002,
        parent: 113000,
        description: '标记垃圾桶满溢布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'materialsDumpingDetail',
        text: '处理提醒信息',
        code: 113202,
        parent: 113200,
        description: '标记乱堆物料布控告警为<result>，信息ID:<id>'
      }
    ]
  },
  alarmsDetail: {
    value: `${api}prevention/result/${version}/alarms/<id>`,
    label: '查询防控事件告警列表',
    actionName: 'alarmsDetail',
    logInfo: [
      {
        type: 'controlRealAlarm',
        text: '查看提醒信息',
        code: 109701,
        parent: 109700
      },
      {
        type: 'noCapDetail',
        text: '查看提醒信息',
        code: 111201,
        parent: 111200,
        description: '查看无帽进入后厨的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'umbrellaDetail',
        text: '查看提醒信息',
        code: 111401,
        parent: 111400,
        description: '查看撑伞经营的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'nonVehicleParkingDetail',
        text: '查看提醒信息',
        code: 111601,
        parent: 111600,
        description: '查看非机动乱停乱放的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'dryingStreetDetail',
        text: '查看提醒信息',
        code: 111801,
        parent: 111800,
        description: '查看沿街晾晒的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'hawkerStreetDetail',
        text: '查看提醒信息',
        code: 112001,
        parent: 112000,
        description: '查看沿街小贩的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'chamdowOperationsDetail',
        text: '查看提醒信息',
        code: 112201,
        parent: 112200,
        description: '查看占道经营的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'chamdowBillboardDetail',
        text: '查看提醒信息',
        code: 112401,
        parent: 112400,
        description: '查看占道广告牌的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'garbageExposedDetail',
        text: '查看提醒信息',
        code: 112601,
        parent: 112600,
        description: '查看垃圾暴露的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'garbageDumpingDetail',
        text: '查看提醒信息',
        code: 112801,
        parent: 112800,
        description: '查看垃圾袋乱堆的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'garbageOverflowDetail',
        text: '查看提醒信息',
        code: 113001,
        parent: 113000,
        description: '查看垃圾桶满溢的布控告警信息, 信息ID: <id>'
      },
      {
        type: 'materialsDumpingDetail',
        text: '查看提醒信息',
        code: 113201,
        parent: 113200,
        description: '查看乱堆物料的布控告警信息, 信息ID: <id>'
      }
    ]
  },
  countAlarmResultsByHandleType: {
    value: `${api}prevention/statistics/${version}/countAlarmResultsByHandleType`,
    label: '按处理类型统计防控事件（未处理、有效、无效）',
    actionName: 'countAlarmResultsByHandleType'
  },
  countAlarmResultsTrendByEventType: {
    value: `${api}prevention/statistics/${version}/countAlarmResultsTrendByEventType`,
    label: '按事件类型统计每日告警量',
    actionName: 'countAlarmResultsTrendByEventType'
  },
  countTaskUnhandledNum: {
    value: `${api}prevention/statistics/${version}/countTaskUnhandledNum`,
    label: '防控任务未处理告警数统计',
    actionName: 'countTaskUnhandledNum'
  },
  addTask: {
    value: `${api}prevention/task/${version}/addTask`,
    label: '防控任务未处理告警数统计',
    actionName: 'addTask',
    logInfo: [
      {
        type: '101509',
        text: '新增布控任务',
        code: 111302,
        parent: 111300,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101513',
        text: '新增布控任务',
        code: 111502,
        parent: 111500,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101510',
        text: '新增布控任务',
        code: 111702,
        parent: 111700,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101511',
        text: '新增布控任务',
        code: 111902,
        parent: 111900,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101512',
        text: '新增布控任务',
        code: 112102,
        parent: 112100,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101514',
        text: '新增布控任务',
        code: 112302,
        parent: 112300,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101515',
        text: '新增布控任务',
        code: 112502,
        parent: 112500,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101516',
        text: '新增布控任务',
        code: 112702,
        parent: 112700,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101517',
        text: '新增布控任务',
        code: 112902,
        parent: 112900,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101518',
        text: '新增布控任务',
        code: 113102,
        parent: 113100,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101519',
        text: '新增布控任务',
        code: 113302,
        parent: 113300,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101530',
        text: '新增布控任务',
        code: 113302,
        parent: 113300,
        description: '新增防控任务【<taskName>】'
      }
    ]
  },
  updateTaskStatus: {
    value: `${api}prevention/task/${version}/updateTaskStatus`,
    label: '启停任务',
    actionName: 'updateTaskStatus',
    logInfo: [
      {
        type: '101509',
        text: '布控任务开始/暂停',
        code: 111301,
        parent: 111300,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101513',
        text: '布控任务开始/暂停',
        code: 111501,
        parent: 111500,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101510',
        text: '布控任务开始/暂停',
        code: 111701,
        parent: 111700,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101511',
        text: '布控任务开始/暂停',
        code: 111901,
        parent: 111900,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101512',
        text: '布控任务开始/暂停',
        code: 112101,
        parent: 112100,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101514',
        text: '布控任务开始/暂停',
        code: 112301,
        parent: 112300,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101515',
        text: '布控任务开始/暂停',
        code: 112501,
        parent: 112500,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101516',
        text: '布控任务开始/暂停',
        code: 112701,
        parent: 112700,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101517',
        text: '布控任务开始/暂停',
        code: 112901,
        parent: 112900,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101518',
        text: '布控任务开始/暂停',
        code: 113101,
        parent: 113100,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101519',
        text: '布控任务开始/暂停',
        code: 113301,
        parent: 113300,
        description: '<startOrStop>防控任务【<taskName>】'
      },
      {
        type: '101530',
        text: '布控任务开始/暂停',
        code: 113301,
        parent: 113300,
        description: '<startOrStop>防控任务【<taskName>】'
      }
    ]
  },
  deleteTask: {
    value: `${api}prevention/task/${version}/deleteTask/<id>`,
    label: '删除任务',
    actionName: 'deleteTask',
    logInfo: [
      {
        type: '101509',
        text: '删除布控任务',
        code: 111304,
        parent: 111300,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101513',
        text: '删除布控任务',
        code: 111504,
        parent: 111500,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101510',
        text: '删除布控任务',
        code: 111704,
        parent: 111700,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101511',
        text: '删除布控任务',
        code: 111904,
        parent: 111900,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101512',
        text: '删除布控任务',
        code: 112104,
        parent: 112100,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101514',
        text: '删除布控任务',
        code: 112304,
        parent: 112300,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101515',
        text: '删除布控任务',
        code: 112504,
        parent: 112500,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101516',
        text: '删除布控任务',
        code: 112704,
        parent: 112700,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101517',
        text: '删除布控任务',
        code: 112904,
        parent: 112900,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101518',
        text: '删除布控任务',
        code: 113104,
        parent: 113100,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101519',
        text: '删除布控任务',
        code: 113304,
        parent: 113300,
        description: '删除防控任务【<taskName>】'
      },
      {
        type: '101530',
        text: '删除布控任务',
        code: 113304,
        parent: 113300,
        description: '删除防控任务【<taskName>】'
      }
    ]
  },
  taskDetail: {
    value: `${api}prevention/task/${version}/tasks/<id>`,
    label: '查询防控任务列表',
    actionName: 'taskDetail'
  },
  updateTask: {
    value: `${api}prevention/task/${version}/updateTask`,
    label: '更新防控任务',
    actionName: 'updateTask',
    logInfo: [
      {
        type: '101509',
        text: '编辑布控任务',
        code: 111303,
        parent: 111300,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101513',
        text: '编辑布控任务',
        code: 111503,
        parent: 111500,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101510',
        text: '编辑布控任务',
        code: 111703,
        parent: 111700,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101511',
        text: '编辑布控任务',
        code: 111903,
        parent: 111900,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101512',
        text: '编辑布控任务',
        code: 112103,
        parent: 112100,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101514',
        text: '编辑布控任务',
        code: 112303,
        parent: 112300,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101515',
        text: '编辑布控任务',
        code: 112503,
        parent: 112500,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101516',
        text: '编辑布控任务',
        code: 112703,
        parent: 112700,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101517',
        text: '编辑布控任务',
        code: 112903,
        parent: 112900,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101518',
        text: '编辑布控任务',
        code: 113103,
        parent: 113100,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101519',
        text: '编辑布控任务',
        code: 113303,
        parent: 113300,
        description: '修改防控任务【<taskName>】'
      },
      {
        type: '101530',
        text: '编辑布控任务',
        code: 113303,
        parent: 113300,
        description: '修改防控任务【<taskName>】'
      }
    ]
  },
  countPreventionTaskByType: {
    value: `${api}prevention/statistics/${version}/countPreventionTaskByType`,
    label: '更新防控任务',
    actionName: 'countPreventionTaskByType'
  },
  updateTask2: {
    value: `${api}task/${version}/updateTask`,
    label: '更新防控任务',
    actionName: 'updateTask2'
  },
  setControlCenterIgnoreAlarm: {
    value: `${api}prevention/task/${version}/setIgnoreAlarm`,
    label: '：启停任务告警实时推送功能',
    actionName: 'setControlCenterIgnoreAlarm'
  }
};
