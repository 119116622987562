import { httpRequest } from './http'
import controlCenter from './url/controlCenter'
import _ from 'lodash';

@httpRequest
class ControlCenter {
  queryTasks(options) {
    return this.$httpRequest({
      url: controlCenter.queryTasks.value,
      method: 'post',
      data: options
    })
  }
  queryAlarms(options) {
    return this.$httpRequest({
      url: controlCenter.queryAlarms.value,
      method: 'post',
      data: options
    })
  }
  alarmsDetail(options, logOption) {
    let logData = controlCenter.alarmsDetail.logInfo.find(v => v.type === logOption.type);
    let description = logData.description.replace('<id>', options.id);
    if (!logOption.isHistoryAlarm) {
      logData = controlCenter.alarmsDetail.logInfo[0];
    }
    return this.$httpRequest({
      url: controlCenter.alarmsDetail.value.replace('<id>', options.id),
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description
      }
    })
  }
  dealAlarm(options, logOption) {
    let logData = controlCenter.dealAlarm.logInfo.find(v => v.type === logOption.type);
    const isEffective = options.violationStatus ? '有效' : '无效';
    let description = logData.description.replace('<id>', options.id).replace('<result>', isEffective);
    if (!logOption.isHistoryAlarm) {
      logData = controlCenter.dealAlarm.logInfo[0];
    }
    return this.$httpRequest({
      url: controlCenter.dealAlarm.value,
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description
      }
    })
  }
  countAlarmResultsByHandleType(options) {
    return this.$httpRequest({
      url: controlCenter.countAlarmResultsByHandleType.value,
      method: 'post',
      data: options
    })
  }
  countAlarmResultsTrendByEventType(options) {
    return this.$httpRequest({
      url: controlCenter.countAlarmResultsTrendByEventType.value,
      method: 'post',
      data: options
    })
  }
  countTaskUnhandledNum(options) {
    return this.$httpRequest({
      url: controlCenter.countTaskUnhandledNum.value,
      method: 'post',
      data: options
    })
  }
  addTask(options) {
    let logInfo = _.cloneDeep(controlCenter.addTask.logInfo).find(v => v.type === options.type) || {};
    logInfo.description = logInfo.description.replace('<taskName>', options.name);
    return this.$httpRequest({
      url: controlCenter.addTask.value,
      method: 'post',
      data: options,
      logInfo
    })
  }
  updateTaskStatus(options, logOption){
    let logInfo = _.cloneDeep(controlCenter.updateTaskStatus.logInfo).find(v => v.type == logOption.taskType) || {};
    logInfo.description = logInfo.description.replace('<startOrStop>', options.suspend ? '暂停' : '开始').replace('<taskName>', logOption.taskName);
    return this.$httpRequest({
      url: controlCenter.updateTaskStatus.value,
      method: 'post',
      data: options,
      logInfo
    })
  }
  deleteTask({ id, taskType, taskName }){
    let logInfo = _.cloneDeep(controlCenter.deleteTask.logInfo).find(v => v.type == taskType) || {};
    logInfo.description = logInfo.description.replace('<taskName>', taskName);
    return this.$httpRequest({
      url: controlCenter.deleteTask.value.replace('<id>', id),
      method: 'post',
      data: { id },
      logInfo
    })
  }
  taskDetail(id) {
    return this.$httpRequest({
      url: controlCenter.taskDetail.value.replace('<id>', id),
      method: 'post',
      data: { id }
    })
  }
  updateTask(options){
    let logInfo = _.cloneDeep(controlCenter.updateTask.logInfo).find(v => v.type === options.type) || {};
    logInfo.description = logInfo.description.replace('<taskName>', options.name);
    return this.$httpRequest({
      url: controlCenter.updateTask.value,
      method: 'post',
      data: options,
      logInfo
    })
  }
  countPreventionTaskByType(options) {
    return this.$httpRequest({
      url: controlCenter.countPreventionTaskByType.value,
      method: 'post',
      data: options
    })
  }
  
  updateTask2(options){
    let logInfo = _.cloneDeep(controlCenter.updateTask.logInfo).find(v => v.type === options.type) || {};
    logInfo.description = logInfo.description.replace('<taskName>', options.name);
    return this.$httpRequest({
      url: controlCenter.updateTask2.value,
      method: 'post',
      data: options,
      logInfo
    })
  }

  // 启停任务告警实时推送功能
  setControlCenterIgnoreAlarm(options){
    return this.$httpRequest({
      url: controlCenter.setControlCenterIgnoreAlarm.value,
      method: 'post',
      data: options
    })
  }
}
export default new ControlCenter()
