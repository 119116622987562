import config from '../config';
const { api, version } = config;
// 布控库url
export default {
  moniteeFaceTaskModule: {
    text: '重点人员布控任务',
    code: 105100
  },
  enterMoniteeFaceTaskModule: {
    text: '进入重点人员布控布控任务管理界面',
    code: 105199,
    parent: 105100,
    moduleName: 'keyPersonnelTaskView'
  },
  moniteeOutsidersTaskModule: {
    text: '外来人员布控任务',
    code: 106100
  },
  enterMoniteeOutsidersTaskModule: {
    text: '进入外来人员布控布控任务管理界面',
    code: 106199,
    parent: 106100,
    moduleName: 'outsiderTaskView'
  },
  moniteeAIOTaskModule: {
    text: '专网套件布控任务',
    code: 106300
  },
  enterMoniteeAIOTaskModule: {
    text: '进入专网套件布控布控任务管理界面',
    code: 106399,
    parent: 106300,
    moduleName: 'privateNetTaskView'
  },
  moniteePhantomTaskModule: {
    text: '人员入侵布控任务',
    code: 106600
  },
  enterMoniteePhantomTaskModule: {
    text: '进入人员入侵布控任务管理界面',
    code: 106699,
    parent: 106600,
    moduleName: 'eventTaskView'
  },
  vehicleTaskViewModule: {
    text: '重点车辆布控任务',
    code: 114300
  },
  entervehicleTaskViewModule: {
    text: '进入重点车辆布控任务管理界面',
    code: 114399,
    parent: 114300,
    moduleName: 'vehicleTaskView'
  },
  vehicleTemporaryTaskViewModule: {
    text: '外来车辆布控任务',
    code: 114700
  },
  entervehicleTemporaryTaskViewModule: {
    text: '进入外来车辆布控任务管理界面',
    code: 114799,
    parent: 114700,
    moduleName: 'vehicleTemporaryTaskView'
  },
  queryMonitorTasks: {
    value: `${api}alarm/v2/monitorTask/queryMonitorTasks`,
    label: '获取布控任务列表',
    actionName: 'queryMonitorTasks'
  },
  setWhetherIgnoreAlarm: {
    value: `${api}alarm/${version}/monitorTask/setWhetherIgnoreAlarm`,
    label: '设置忽略/取消忽略布控任务报警',
    actionName: 'setWhetherIgnoreAlarm'
  },
  countTaskUnhandledNum: {
    value: `${api}alarm/${version}/monitorTask/countTaskUnhandledNum`,
    label: '设置忽略/取消忽略布控任务报警',
    actionName: 'countTaskUnhandledNum'
  },
  countTaskAlarmsResult: {
    value: `${api}alarm/${version}/countTaskAlarmsResult`,
    label: '设置忽略/取消忽略布控任务报警',
    actionName: 'countTaskAlarmsResult'
  },
  changeMonitorTaskRunStatus: {
    value: `${api}alarm/${version}/monitorTask/changeMonitorTaskRunStatus`,
    label: '批量开启或暂停布控任务',
    actionName: 'changeMonitorTaskRunStatus',
    logInfo: [
      {
        type: 'faceTask',
        text: '开始/暂停布控任务',
        code: 105106,
        parent: 105100
      },
      {
        type: 'outsidersTask',
        text: '开始/暂停布控任务',
        code: 106101,
        parent: 106100
      },
      {
        type: 'AIOTask',
        text: '开始/暂停布控任务',
        code: 106301,
        parent: 106300
      },
      {
        type: 'phantomTask',
        text: '开始/暂停布控任务',
        code: 106601,
        parent: 106600
      }
    ]
  },
  deleteMonitorTask: {
    value: `${api}alarm/${version}/monitorTask/deleteMonitorTask/<id>`,
    label: '删除布控任务',
    actionName: 'deleteMonitorTask',
    logInfo: [
      {
        type: 'faceTask',
        text: '删除布控任务',
        code: 105107,
        parent: 105100
      },
      {
        type: 'outsidersTask',
        text: '删除布控任务',
        code: 106104,
        parent: 106100
      },
      {
        type: 'AIOTask',
        text: '删除布控任务',
        code: 106304,
        parent: 106300
      },
      {
        type: 'phantomTask',
        text: '删除布控任务',
        code: 106604,
        parent: 106600
      }
    ]
  },
  addMonitorTask: {
    value: `${api}alarm/${version}/monitorTask/addMonitorTask`,
    label: '新增布控任务',
    actionName: 'addMonitorTask',
    logInfo: [
      {
        type: 'faceTask',
        text: '新增布控任务',
        code: 105102,
        parent: 105100
      },
      {
        type: 'outsidersTask',
        text: '新增布控任务',
        code: 106102,
        parent: 106100
      },
      {
        type: 'AIOTask',
        text: '新增布控任务',
        code: 106302,
        parent: 106300
      },
      {
        type: 'phantomTask',
        text: '新增布控任务',
        code: 106602,
        parent: 106600
      }
    ]
  },
  updateMonitorTask: {
    value: `${api}alarm/${version}/monitorTask/updateMonitorTask`,
    label: '修改布控任务',
    actionName: 'updateMonitorTask',
    logInfo: [
      {
        type: 'faceTask',
        text: '编辑布控任务基本信息',
        code: 105104,
        parent: 105100
      },
      {
        type: 'outsidersTask',
        text: '编辑布控任务基本信息',
        code: 106103,
        parent: 106100
      },
      {
        type: 'AIOTask',
        text: '编辑布控任务基本信息',
        code: 106303,
        parent: 106300
      },
      {
        type: 'phantomTask',
        text: '编辑布控任务基本信息',
        code: 106603,
        parent: 106600
      }
    ]
  },
  queryMonitorTaskDetail: {
    value: `${api}alarm/${version}/monitorTask/monitorTasks/<id>`,
    label: '查询布控任务详情',
    actionName: 'queryMonitorTaskDetail'
  },
  queryMonitorTaskByMonitorLibId: {
    value: `${api}alarm/v2/monitorTask/queryMonitorTasksByMonitorLibId`,
    label: '查询布控库关联的布控任务',
    actionName: 'queryMonitorTaskByMonitorLibId'
  },
  // 查询用户是否有创建布控库和布控任务（不包括临控任务和临控任务）
  checkUserMonitorInfos: {
    value: `${api}alarm/${version}/statistics/checkUserMonitorInfos`,
    label: '查询用户是否有创建布控库和布控任务',
    actionName: 'checkUserMonitorInfos'
  },
  //车辆布控
  getTasks: {
    value: `${api}task/${version}/getTasks`,
    label: '获取车辆布控任务列表',
    actionName: 'getTasks'
  },
  saveVehiclesTask: {
    value: `${api}task/${version}/saveVehiclesTask`,
    label: '新建车辆布控任务',
    actionName: 'saveVehiclesTask',
    logInfo: [
      {
        type: '101520',
        text: '新增布控任务',
        code: 114302,
        parent: 114300,
        description: '新增重点车辆布控任务【<name>】'
      },
      {
        type: '101521',
        text: '新增布控任务',
        code: 114702,
        parent: 114700,
        description: '新增外来车辆布控任务【<name>】'
      }
    ]
  },
  updateVehiclesTask: {
    value: `${api}task/${version}/updateVehiclesTask`,
    label: '修改布控任务',
    actionName: 'updateVehiclesTask',
    logInfo: [
      {
        type: '101520',
        text: '编辑布控任务',
        code: 114303,
        parent: 114300,
        description: '编辑重点车辆布控任务【<name>】'
      },
      {
        type: '101521',
        text: '编辑布控任务',
        code: 114703,
        parent: 114700,
        description: '编辑外来车辆布控任务【<name>】'
      }
    ]
  },
  deleteVehiclesTask: {
    value: `${api}task/${version}/deleteTask`,
    label: '删除布控任务',
    actionName: 'deleteVehiclesTask',
    logInfo: [
      {
        type: '101520',
        text: '删除布控任务',
        code: 114307,
        parent: 114300,
        description: '删除重点车辆布控任务【<name>】'
      },
      {
        type: '101521',
        text: '删除布控任务',
        code: 114707,
        parent: 114700,
        description: '删除外来车辆布控任务【<name>】'
      },
      {
        type: '101509',
        text: '布控任务删除',
        code: 111304,
        parent: 111300,
        description: '删除防控任务【<name>】'
      },
      {
        type: '101530',
        text: '布控任务删除',
        code: 111304,
        parent: 111300,
        description: '删除防控任务【<name>】'
      }
    ]
  },
  getTaskById: {
    value: `${api}task/${version}/getTaskById`,
    label: '根据ID查询布控任务',
    actionName: 'getTaskById'
  },
  updateTaskStop: {
    value: `${api}task/${version}/updateTaskStop`,
    label: '布控任务启停',
    actionName: 'updateTaskStop',
    logInfo: [
      {
        type: '101520',
        text: '开始/暂停布控任务',
        code: 114301,
        parent: 114300,
        description: '<handle>重点车辆布控任务【<name>】'
      },
      {
        type: '101521',
        text: '开始/暂停布控任务',
        code: 114701,
        parent: 114700,
        description: '<handle>外来车辆布控任务【<name>】'
      },
      {
        type: '101509',
        text: '开始/暂停布控任务',
        code: 111301,
        parent: 111300,
        description: '<handle>防控任务【<name>】'
      },
      {
        type: '101530',
        text: '开始/暂停布控任务',
        code: 111301,
        parent: 111300,
        description: '<handle>防控任务【<name>】'
      }
    ]
  },
  countAlarmByTaskId: {
    value: `${api}alarm/${version}/countAlarmByTaskId`,
    label: '布控任务启停',
    actionName: 'countAlarmByTaskId'
  },
  updateTaskPush: {
    value: `${api}task/${version}/updateTaskPush`,
    label: '布控任务启停',
    actionName: 'updateTaskPush'
  },
  saveTask: {
    value: `${api}task/${version}/saveTask`,
    label: '布控任务启停',
    actionName: 'saveTask',
    logInfo: [
      {
        type: '101509',
        text: '布控任务新增',
        code: 111302,
        parent: 111300,
        description: '新增防控任务【<taskName>】'
      },
      {
        type: '101530',
        text: '布控任务新增',
        code: 111302,
        parent: 111300,
        description: '新增防控任务【<taskName>】'
      }
    ]
  }
};
