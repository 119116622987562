import alarm from '../url/alarm';
import { httpRequest } from '../http';

const alarmType = {
  1: 'faceAlarm', // 重点人员告警
  2: 'outsidersAlarm', // 外来人员告警
  3: 'phantomAlarm', // 魅影历史提醒
  4: 'AIOAlarm', // 专网套件告警
  5: 'personnelRealAlarm', // 警情处理
  6: 'phantomRealAlarm' // 魅影实时提醒
};

@httpRequest
class AlarmResult {
  /**
   * @desc 获取人员警情列表
   * @param {Object} data
   */
  queryAlarmResults(data) {
    BaseStore.action.setAction(alarm.queryAlarmResults.actionName);
    return this.$httpRequest({
      url: alarm.queryAlarmResults.value,
      data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.queryAlarmResults.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.queryAlarmResults.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取车辆警情列表
   * @param {Object} data
   */
  getTaskAlarms(data) {
    BaseStore.action.setAction(alarm.getTaskAlarms.actionName);
    return this.$httpRequest({
      url: alarm.getTaskAlarms.value,
      data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.getTaskAlarms.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.getTaskAlarms.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 车辆历史告警列表
   * @param {*} data 
   */
  getTaskAlarmsResult(data, logOption) {
    BaseStore.action.setAction(alarm.getTaskAlarmsResult.actionName);
    let logInfo;
    if (logOption) {
      const logData = alarm.getTaskAlarmsResult.logInfo.find(v => v.type === logOption.libType);
      logInfo = {
        ...logData,
        description: logData.description.replace('<id>', logOption.id)
      }
    }
    return this.$httpRequest({
      url: alarm.getTaskAlarmsResult.value,
      data,
      method: 'POST',
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.getTaskAlarmsResult.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.getTaskAlarmsResult.actionName);
        return Promise.reject(e);
      });
  }

  /**
 * 人员警情（总数、未处理、
 * @param {*} data 
 */
  countAlarmResults(data) {
    BaseStore.action.setAction(alarm.countAlarmResults.actionName);
    return this.$httpRequest({
      url: alarm.countAlarmResults.value,
      data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.countAlarmResults.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.countAlarmResults.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 车辆警情（总数、未处理、
   * @param {*} data 
   */
  countTaskAlarmsResult(data) {
    BaseStore.action.setAction(alarm.countTaskAlarmsResult.actionName);
    return this.$httpRequest({
      url: alarm.countTaskAlarmsResult.value,
      data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.countTaskAlarmsResult.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.countTaskAlarmsResult.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查看历史警情详情
   * @param {Object} data
   */
  alarmResults({ id, libType, isRealAlarm }) {
    BaseStore.action.setAction(alarm.alarmResults.actionName);
    let logType = libType;
    if (isRealAlarm) {
      logType = libType !== 3 ? 5 : 6;
    }
    let description = '';
    const sceneCode = BaseStore.user.appInfo.sceneCode;
    const monitorLabel = Dict.map.monitorLabel[sceneCode];
    switch (+libType) {
      case 1:
        description = `查看${monitorLabel.keyPerson.label}布控告警信息, 信息ID：${id}`;
        break;
      case 2:
        description = `查看${monitorLabel.outsider.label}布控告警信息, 信息ID：${id}`;
        break;
      case 3:
        description = `查看人员入侵布控告警信息, 信息ID：${id}`;
        break;
      case 4:
        description = `查看专网套件布控告警信息, 信息ID：${id}`;
        break;
      default:
        break;
    }
    const logInfo = {
      description,
      ...alarm.alarmResults.logInfo.find(v => v.type === alarmType[logType])
    };
    return this.$httpRequest({
      url: alarm.alarmResults.value.replace('<id>', id),
      data: { id },
      method: 'POST',
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.alarmResults.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.alarmResults.actionName);
        return Promise.reject(e);
      });
  }

  /**警情处理 */
  handleAlarmResult = (data, logData = {}) => {
    BaseStore.action.setAction(alarm.handleAlarmResult.actionName);
    let logType = logData.libType;
    if (logData.isRealAlarm) {
      logType = logData.libType !== 3 ? 5 : 6;
    }
    let description = '';
    const sceneCode = BaseStore.user.appInfo.sceneCode;
    const monitorLabel = Dict.map.monitorLabel[sceneCode];
    const isEffective = data.isEffective ? '有效' : '无效';
    switch (+logType) {
      case 1:
        description = `标记${monitorLabel.keyPerson.label}布控告警为【${isEffective}】, 信息ID：${
          data.id
          }`;
        break;
      case 2:
        description = `标记${monitorLabel.outsider.label}布控告警为【${isEffective}】, 信息ID：${
          data.id
          }`;
        break;
      case 3:
        description = `标记人员入侵布控告警为【${isEffective}】, 信息ID：${
          data.id
          }`;
        break;
      default:
        break;
    }
    const logInfo = {
      description,
      ...alarm.handleAlarmResult.logInfo.find(
        v => v.type === alarmType[logType]
      )
    };
    return this.$httpRequest({
      url: alarm.handleAlarmResult.value,
      data: data,
      method: 'POST',
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.handleAlarmResult.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.handleAlarmResult.actionName);
        return Promise.reject(e);
      });
  };

  // 处理车辆告警
  updateTaskAlarm(options, logOption) {
    const logData = alarm.updateTaskAlarm.logInfo.find(v => v.type === logOption.libType)
    const isEffective = options.isEffective ? '有效' : '无效';
    return this.$httpRequest({
      url: alarm.updateTaskAlarm.value,
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description: logData.description.replace('<result>', isEffective).replace('<id>', options.id)
      }
    })
  }

/**
   * 根据ID获取车辆告警详情（结构化信息）
   * @param {*} data 
   */
  getTaskAlarmById(data) {
    BaseStore.action.setAction(alarm.getTaskAlarmById.actionName);
    return this.$httpRequest({
      url: `${alarm.getTaskAlarmById.value}/${data.id}`,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(alarm.getTaskAlarmById.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(alarm.getTaskAlarmById.actionName);
        return Promise.reject(e);
      });
  }


}

export default new AlarmResult();
