import { httpRequest } from "./http";
import logStatistics from "./url/logStatistics";
import * as _ from "lodash";

@httpRequest
class logStatisticsService {
 
  queryList(data) {
    return this.$httpRequest({
      url: logStatistics.queryLogStatistics.value,
      method: "post",
      data
    }).then(res => {
      return res;
    });
  }

  //导出
  exportLogs(data) {
    return fetch(logStatistics.exportLogs.value,{
      method:'post',
      body:JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: Utils.getCache('token', 'session')
      }
    }).then(res => res.blob()).then(blob => {
      var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = '日志统计列表.xlsx';
        link.click();
        window.URL.revokeObjectURL(link.href);
        return Promise.resolve();
    }).catch(e => {
      return Promise.reject(e);
    });
   
  }

  //查询活跃用户数量
  queryActiveUserNumber(data){
    return this.$httpRequest({
      url: logStatistics.queryActiveUserNumber.value,
      method: 'post',
      data: data
    })
  }
//查询图库使用率
queryDataResourceStatistics(data){
  return this.$httpRequest({
    url: logStatistics.queryDataResourceStatistics.value,
    method: 'post',
    data: data
  })
}
//查询功能使用率
queryFunctionUsageStatistics(data){
  return this.$httpRequest({
    url: logStatistics.queryFunctionUsageStatistics.value,
    method: 'post',
    data: data
  })
}
//查询模块使用率
queryModuleUsageStatistics(data){
  return this.$httpRequest({
    url: logStatistics.queryModuleUsageStatistics.value,
    method: 'post',
    data: data
  })
}

}

export default new logStatisticsService();
