import { httpRequest } from './http';
import favorite from './url/favorite.js';

@httpRequest
class FavoriteService {
  /**
   * 获取用户收藏的图片列表
   * @param {*} options
   */
  queryPictureFavorites(options) {
    BaseStore.action.setAction(favorite.queryPictureFavorites.actionName)
    return this.$httpRequest({
      method: "post",
      url: favorite.queryPictureFavorites.value,
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(favorite.queryPictureFavorites.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(favorite.queryPictureFavorites.actionName);
        return Promise.reject(e);
      });
  }

   /**
   * 删除图片
   * @param {*} options
   */
  deleteFavorite(options) {
    BaseStore.action.setAction(favorite.deleteFavorite.actionName)
    return this.$httpRequest({
      method: "post",
      url: favorite.deleteFavorite.value,
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(favorite.deleteFavorite.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(favorite.deleteFavorite.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 新增图片收藏
   * @param {*} options
   */
  addPictureFavorite(options) {
    BaseStore.action.setAction(favorite.addPictureFavorite.actionName)
    return this.$httpRequest({
      method: "post",
      url: favorite.addPictureFavorite.value,
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(favorite.addPictureFavorite.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(favorite.addPictureFavorite.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 批量删除图片
   * @param {*} options
   */
  deleteFavoriteBatch(options) {
    BaseStore.action.setAction(favorite.deleteFavoriteBatch.actionName)
    return this.$httpRequest({
      method: "post",
      url: favorite.deleteFavoriteBatch.value,
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(favorite.deleteFavoriteBatch.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(favorite.deleteFavoriteBatch.actionName);
        return Promise.reject(e);
      });
  }

}

export default new FavoriteService();