import config from '../config';
const { api, version } = config;

export default {
  addFavorite:{
    value:`${api}user/favorite/${version}/addFavorite`,
    label:'新增收藏',
    actionName:'addFavorite'
  },
  batchDeleteFavorite:{
    value:`${api}user/favorite/${version}/batchDeleteFavorite`,
    label:'批量删除收藏',
    actionName:'batchDeleteFavorite'
  },
  deleteFavorite:{
    value:`${api}user/favorite/${version}/deleteFavorite`,
    label:'删除收藏',
    actionName:'deleteFavorite'
  },
  queryAlarmFavorites:{
    value:`${api}user/favorite/${version}/queryAlarmFavorites`,
    label:'获取收藏警情列表',
    actionName:'queryAlarmFavorites'
  },
  queryDeviceFavorites:{
    value:`${api}user/favorite/${version}/queryDeviceFavorites`,
    label:'获取收藏设备列表',
    actionName:'queryDeviceFavorites'
  },
  // 以下为新增的web端接口
  addPictureFavorite: {
    value:`${api}user/favorite/${version}/addPictureFavorite`,
    label:'新增图片收藏',
    actionName:'addPictureFavorite'
  },
  queryPictureFavorites: {
    value:`${api}user/favorite/${version}/queryPictureFavorites`,
    label:'获取用户收藏的图片列表',
    actionName:'queryPictureFavorites'
  },
  deleteFavoriteBatch: {
    value:`${api}user/favorite/${version}/deleteFavoriteBatch`,
    label:'批量删除收藏',
    actionName:'deleteFavoriteBatch'
  }
}