import Config from '../config';
const { api ,version} = Config;
export default {
  queryLogStatistics: {
    value: `${api}log/${version}/queryLogStatistics`,
    lable: '系统数据列表',
    actionName: 'queryLogStatistics',
  },
  exportLogs: {
    value: `${api}log/${version}/exportLogStatistics`,
    lable: '导出',
    actionName: 'exportLogStatistics',
  },


  queryActiveUserNumber:{
    value: `${api}log/${version}/queryActiveUserNumber`,
    lable: '查询活跃用户数量',
    actionName: 'queryActiveUserNumber',
  },
  queryDataResourceStatistics: {
    value: `${api}log/${version}/queryDataResourceStatistics`,
    lable: '查询图库使用率',
    actionName: 'queryDataResourceStatistics',
  },
  queryFunctionUsageStatistics: {
    value: `${api}log/${version}/queryFunctionUsageStatistics`,
    lable: '查询功能使用率',
    actionName: 'queryFunctionUsageStatistics',
  },


  queryModuleUsageStatistics:{
    value: `${api}log/${version}/queryModuleUsageStatistics`,
    lable: '查询模块使用率',
    actionName: 'queryModuleUsageStatistics',
  }

}