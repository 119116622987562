import { httpRequest } from './http';
import massControl from './url/massControl';
import moment from 'moment';

@httpRequest
class MassControlServise {
  /**
   * @desc 处理线索
   * @param {Object} data
   */
  dealClue(data, mass) {
    BaseStore.action.setAction(massControl.dealClue.actionName);
    return this.$httpRequest({
      url: massControl.dealClue.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `处理【${Dict.getLabel('massType', mass.type)}】的线索，线索ID：${mass.id}`,
        ...massControl.dealClue.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.dealClue.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.dealClue.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 删除线索
   * @param {Object} data
   */
  deleteClue(data, list) {
    BaseStore.action.setAction(massControl.deleteClue.actionName);
    return this.$httpRequest({
      url: massControl.deleteClue.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: list.map(v => `删除【${Dict.getLabel('massType', v.type)}】的线索，线索ID：${v.id}`).join(),
        ...massControl.dealClue.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.deleteClue.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.deleteClue.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 导出excel
   * @param {Object} data
   */
  exportClueByIds(data) {
    BaseStore.action.setAction(massControl.exportClueByIds.actionName);
    return fetch(massControl.exportClueByIds.value, {
      body: JSON.stringify(data),
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Utils.getCache('token', 'session')
      }
    })
      .then(res => res.blob())
      .then(blob => {
        let time = moment().format('YYYYMMDD');
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `线索列表${time}.xlsx`;
        document.body.appendChild(link);
        link.click();
        BaseStore.action.removeAction(massControl.exportClueByIds.actionName);
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.exportClueByIds.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查看线索列表
   * @param {Object} data
   */
  queryClueList(data) {
    BaseStore.action.setAction(massControl.queryClueList.actionName);
    return this.$httpRequest({
      url: massControl.queryClueList.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.queryClueList.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.queryClueList.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查看线索对应的评论
   * @param {Object} data
   */
  queryCommentByClueIds(data) {
    BaseStore.action.setAction(massControl.queryCommentByClueIds.actionName);
    return this.$httpRequest({
      url: massControl.queryCommentByClueIds.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.queryCommentByClueIds.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.queryCommentByClueIds.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 上传线索
   * @param {Object} data
   */
  saveClue(data) {
    BaseStore.action.setAction(massControl.saveClue.actionName);
    return this.$httpRequest({
      url: massControl.saveClue.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.saveClue.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.saveClue.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 评论线索
   * @param {Object} data
   */
  saveComment(data, mass) {
    BaseStore.action.setAction(massControl.saveComment.actionName);
    return this.$httpRequest({
      url: massControl.saveComment.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `编辑【${Dict.getLabel('massType', mass.type)}】的线索的处理意见，线索ID：${mass.id}`,
        ...massControl.dealClue.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.saveComment.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.saveComment.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 更新评论
   * @param {Object} data
   */
  updateComment(data, mass) {
    BaseStore.action.setAction(massControl.updateComment.actionName);
    return this.$httpRequest({
      url: massControl.updateComment.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `编辑【${Dict.getLabel('massType', mass.type)}】的线索的处理意见，线索ID：${mass.id}`,
        ...massControl.dealClue.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.updateComment.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.updateComment.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 批量上传图片/视频
   * @param {Object} data
   */
  uploadBatch(data) {
    BaseStore.action.setAction(massControl.uploadBatch.actionName);
    return this.$httpRequest({
      url: massControl.uploadBatch.value,
      data: data,
      method: 'POST',
      timeout: null
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.uploadBatch.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.uploadBatch.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 批量上传图片/视频
   * @param {Object} data
   */
  countClue(data) {
    BaseStore.action.setAction(massControl.countClue.actionName);
    return this.$httpRequest({
      url: massControl.countClue.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(massControl.countClue.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(massControl.countClue.actionName);
        return Promise.reject(e);
      });
  }
}

export default new MassControlServise();
