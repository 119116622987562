import { httpRequest } from '../http'
import _ from 'lodash';
import monitorTask from '../url/monitorTask'

@httpRequest
class MonitorTask {
  getTaskTypeDict() {
    const sceneCode = BaseStore.user.appInfo.sceneCode
    const monitorLabel = Dict.map.monitorLabel[sceneCode]
    const taskTypeDict = [
      {
        type: '101501',
        logType: 'faceTask',
        text: monitorLabel.keyPerson.label + '布控任务'
      },
      {
        type: '101502',
        logType: 'outsidersTask',
        text: monitorLabel.outsider.label + '布控任务'
      },
      { type: '101503', logType: 'phantomTask', text: '人员入侵布控任务' },
      { type: '101504', logType: 'AIOTask', text: '专网套员布控任务' },
      { type: '101525', logType: 'personTrack', text: '隔离人员追踪布控任务' },
    ]
    return taskTypeDict
  }

  /**
   * @desc 获取布控任务列表
   * @param {object} data
   */
  queryMonitorTasks(data = {}, isAlarm = false) {
    if (!isAlarm) {
      data.taskStatuses = [0, 1, 2, 3]
    }
    BaseStore.action.setAction(monitorTask.queryMonitorTasks.actionName)
    return this.$httpRequest({
      url: monitorTask.queryMonitorTasks.value,
      method: 'post',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTasks.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTasks.actionName)
        return Promise.resolve(false)
      })
  }

   /**
   * @desc 获取车辆布控任务列表
   * @param {object} data
   */
  getTasks(data = {}, isAlarm = false) {
    if (!isAlarm) {
      // data.taskStatuses = [0, 1, 2, 3];
      data.taskStatus = 5;
    }
    BaseStore.action.setAction(monitorTask.getTasks.actionName);
    return this.$httpRequest({
      url: monitorTask.getTasks.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.getTasks.actionName);
        if (res.code !== 0) {
          return false;
        }
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.getTasks.actionName);
        return Promise.resolve(false);
      });
  }

  /**
   * @desc 设置忽略/取消忽略他人授权的布控任务报警
   * @param {object} data
   */
  setWhetherIgnoreAlarm(data = {}) {
    BaseStore.action.setAction(monitorTask.setWhetherIgnoreAlarm.actionName)
    return this.$httpRequest({
      url: monitorTask.setWhetherIgnoreAlarm.value,
      method: 'post',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.setWhetherIgnoreAlarm.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.setWhetherIgnoreAlarm.actionName)
        return Promise.reject(e)
      })
  }

  countTaskUnhandledNum(data) {
    BaseStore.action.setAction(monitorTask.countTaskUnhandledNum.actionName)
    return this.$httpRequest({
      url: monitorTask.countTaskUnhandledNum.value,
      method: 'post',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.countTaskUnhandledNum.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.countTaskUnhandledNum.actionName)
        return Promise.reject(e)
      })
  }

  countTaskAlarmsResult(data){
    return this.$httpRequest({
      url: monitorTask.countTaskAlarmsResult.value,
      method: 'post',
      data
    })
  }

  /**
   * @desc 根据id删除布控库
   * @param {string} id 布控任务id
   */
  deleteMonitorTask({ id, taskType, taskName }) {
    BaseStore.action.setAction(monitorTask.deleteMonitorTask.actionName)
    const taskInfo = this.getTaskTypeDict().find(v => v.type === taskType)
    const logInfo = {
      description: `删除${taskInfo.text}【${taskName}】`,
      ...monitorTask.deleteMonitorTask.logInfo.find(v => v.type == taskInfo.logType)
    }

    return this.$httpRequest({
      url: monitorTask.deleteMonitorTask.value.replace('<id>', id),
      method: 'post',
      data: { id },
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.deleteMonitorTask.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.deleteMonitorTask.actionName)
        return Promise.reject(e)
      })
  }

  /**
   * @desc 新增布控任务
   * @param {object} options 布控任务相关数据集合
   * @param {string} taskType 布控任务类型
   */
  addMonitorTask(options, taskType = []) {
    BaseStore.action.setAction(monitorTask.addMonitorTask.actionName)
    const taskInfo = this.getTaskTypeDict().find(v => v.type === (taskType[0] || options.taskType))
    const logInfo = {
      description: `添加${taskInfo.text}【${options.name}】`,
      ...monitorTask.addMonitorTask.logInfo.find(v => v.type === taskInfo.logType)
    }
    return this.$httpRequest({
      url: monitorTask.addMonitorTask.value,
      method: 'post',
      data: options,
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.addMonitorTask.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.addMonitorTask.actionName)
        return Promise.reject(e)
      })
  }

  /**
   * @desc 修改布控任务
   * @param {object} options 布控任务相关数据集合
   * @param {string} taskType 布控任务类型
   */
  updateMonitorTask(options, taskType) {
    BaseStore.action.setAction(monitorTask.updateMonitorTask.actionName)
    const taskInfo = this.getTaskTypeDict().find(v => v.type === (taskType || options.taskType))
    const logInfo = {
      description: `编辑${taskInfo.text}【${options.name}】`,
      ...monitorTask.updateMonitorTask.logInfo.find(v => v.type === taskInfo.logType)
    }
    return this.$httpRequest({
      url: monitorTask.updateMonitorTask.value,
      method: 'post',
      data: options,
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.updateMonitorTask.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.updateMonitorTask.actionName)
        return Promise.reject(e)
      })
  }

  /**
   * @desc 开始暂停任务
   * @param {array} id 布控任务id
   * @param {string} type 开始or暂停
   * @param {string} taskType 布控任务类型
   * @param {string} taskName 布控任务名称
   */
  changeMonitorTaskRunStatus({ ids, type, taskTypes, taskName }) {
    BaseStore.action.setAction(monitorTask.changeMonitorTaskRunStatus.actionName)
    const taskInfo = this.getTaskTypeDict().find(v => v.type === taskTypes[0])
    const logInfo = {
      description: `${type === '1' ? '开启' : '暂停'}${taskInfo.text}【${taskName}】`,
      ...monitorTask.changeMonitorTaskRunStatus.logInfo.find(v => v.type === taskInfo.logType)
    }
    return this.$httpRequest({
      url: monitorTask.changeMonitorTaskRunStatus.value,
      method: 'post',
      data: { taskIds: ids, type },
      logInfo
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.changeMonitorTaskRunStatus.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.changeMonitorTaskRunStatus.actionName)
        return Promise.reject(e)
      })
  }

  /**
   * @desc 根据id获取布控任务详情
   * @param {string} id 布控任务id
   */
  queryMonitorTaskDetail(id) {
    BaseStore.action.setAction(monitorTask.queryMonitorTaskDetail.actionName)
    return this.$httpRequest({
      url: monitorTask.queryMonitorTaskDetail.value.replace('<id>', id),
      method: 'post',
      data: {
        id
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTaskDetail.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTaskDetail.actionName)
        return Promise.reject(e)
      })
  }

  // 查询布控库关联的布控任务
  queryMonitorTaskByMonitorLibId(libId) {
    BaseStore.action.setAction(monitorTask.queryMonitorTaskByMonitorLibId.actionName)
    return this.$httpRequest({
      url: monitorTask.queryMonitorTaskByMonitorLibId.value,
      method: 'post',
      data: { libId }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTaskByMonitorLibId.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.queryMonitorTaskByMonitorLibId.actionName)
        return Promise.resolve(false, e)
      })
  }

  // 查询布控库关联的布控任务
  checkUserMonitorInfos(options) {
    BaseStore.action.setAction(monitorTask.checkUserMonitorInfos.actionName)
    return this.$httpRequest({
      url: monitorTask.checkUserMonitorInfos.value,
      method: 'post',
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.checkUserMonitorInfos.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.checkUserMonitorInfos.actionName)
        return Promise.reject(e)
      })
  }

  // 车辆布控任务新增接口V4.0
  // 新建车辆布控任务
  saveVehiclesTask(options) {
    BaseStore.action.setAction(monitorTask.saveVehiclesTask.actionName)
    const logData = monitorTask.saveVehiclesTask.logInfo.find(v => v.type === options.taskType)
    return this.$httpRequest({
      url: monitorTask.saveVehiclesTask.value,
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description: logData.description.replace('<name>', options.taskName)
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.saveVehiclesTask.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.saveVehiclesTask.actionName)
        return Promise.reject(e)
      })
  }

  // 更新车辆布控任务
  updateVehiclesTask(options, taskType) {
    BaseStore.action.setAction(monitorTask.updateVehiclesTask.actionName)
    const logData = monitorTask.updateVehiclesTask.logInfo.find(v => v.type === taskType)
    return this.$httpRequest({
      url: monitorTask.updateVehiclesTask.value,
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description: logData.description.replace('<name>', options.taskName)
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.updateVehiclesTask.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.updateVehiclesTask.actionName)
        return Promise.reject(e)
      })
  }

  // 删除车辆布控任务
  deleteVehiclesTask({ id, taskType, taskName }) {
    BaseStore.action.setAction(monitorTask.deleteVehiclesTask.actionName)
    const logData = monitorTask.deleteVehiclesTask.logInfo.find(v => v.type === taskType)
    return this.$httpRequest({
      url: monitorTask.deleteVehiclesTask.value,
      method: 'post',
      data: { taskId: id },
      logInfo: {
        ...logData,
        description: logData.description.replace('<name>', taskName)
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.deleteVehiclesTask.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.deleteVehiclesTask.actionName)
        return Promise.reject(e)
      })
  }

  // 查询布控任务详情
  getTaskById(id){
    BaseStore.action.setAction(monitorTask.getTaskById.actionName)
    return this.$httpRequest({
      url: monitorTask.getTaskById.value,
      method: 'post',
      data: {
        id
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.getTaskById.actionName)
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.getTaskById.actionName)
        return Promise.reject(e)
      })
  }

  // 布控任务启动和暂停
  updateTaskStop(options, logOption) {
    BaseStore.action.setAction(monitorTask.updateTaskStop.actionName)
    const logData = monitorTask.updateTaskStop.logInfo.find(v => v.type === logOption.taskType)
    return this.$httpRequest({
      url: monitorTask.updateTaskStop.value,
      method: 'post',
      data: options,
      logInfo: {
        ...logData,
        description: logData.description.replace('<name>', logOption.taskName).replace('<handle>', options.isStop === 1 ? '暂停' : '开始')
      }
    })
      .then(res => {
        BaseStore.action.removeAction(monitorTask.updateTaskStop.actionName)
        if (res.code !== 0) {
          return false
        }
        return res
      })
      .catch(e => {
        BaseStore.action.removeAction(monitorTask.updateTaskStop.actionName)
        return Promise.reject(e)
      })
  }
  countAlarmByTaskId(options){
    return this.$httpRequest({
      url: monitorTask.countAlarmByTaskId.value,
      method: 'post',
      data: options
    })
  }
  updateTaskPush(options){
    return this.$httpRequest({
      url: monitorTask.updateTaskPush.value,
      method: 'post',
      data: options
    })
  }
  saveTask(options){
    let logInfo = _.cloneDeep(monitorTask.saveTask.logInfo).find(v => v.type === options.type) || {};
    logInfo.description = logInfo.description.replace('<taskName>', options.name);
    return this.$httpRequest({
      url: monitorTask.saveTask.value,
      method: 'post',
      data: options,
      logInfo
    })
  }
}

export default new MonitorTask()
