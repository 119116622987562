import { httpRequest } from './http';
import community from './url/community';
import { message } from 'antd';

@httpRequest
class communityService {
  /**
   * 小区总览列表查询
   */
  statisticsList(options = {}) {
    BaseStore.action.setAction(community.statisticsList.value);
    return this.$httpRequest({
      url: community.statisticsList.value,
      method: 'POST',
      data: options
    })
      .then(res => {
        BaseStore.action.removeAction(community.statisticsList.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.statisticsList.actionName);
        return Promise.reject(e);
      });
  }
  //常住人口列表查询
  queryRegisteredPeople(options = {}) {
    let logInfo = {
      ...community.queryRegisteredPeople.logInfo[0]
    };
    BaseStore.action.setAction(community.queryRegisteredPeople.value);
    return this.$httpRequest({
      url: community.queryRegisteredPeople.value,
      method: 'POST',
      data: options,
      logInfo
    })
      .then(res => {
        BaseStore.action.setAction(community.queryRegisteredPeople.actionName);
        return res && res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryRegisteredPeople.actionName);
        return Promise.reject(e);
      });
  }
  // 流动人口列表查询
  queryUnregisteredPeople(options = {}) {
    let logInfo = {
      ...community.queryUnregisteredPeople.logInfo[0]
    };
    BaseStore.action.setAction(community.queryUnregisteredPeople.value);
    return this.$httpRequest({
      url: community.queryUnregisteredPeople.value,
      method: 'POST',
      data: options,
      logInfo
    })
      .then(res => {
        BaseStore.action.setAction(community.queryUnregisteredPeople.actionName);
        return res && res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryUnregisteredPeople.actionName);
        return Promise.reject(e);
      });
  }
  //添加取消关注
  updatePeopleFocus(option) {
    BaseStore.action.setAction(community.updatePeopleFocus.value);
    return this.$httpRequest({
      url: community.updatePeopleFocus.value,
      method: 'post',
      data: option
    })
      .then(() => {
        SocketEmitter.emit(SocketEmitter.eventName.updatePerson, option);
        BaseStore.action.setAction(community.updatePeopleFocus.actionName);
      })
      .catch(e => {
        BaseStore.action.removeAction(community.updatePeopleFocus.actionName);
        return Promise.reject(e);
      });
  }
  //小区人员统计
  countVillagePeople(options = {}) {
    return this.$httpRequest({
      url: community.countVillagePeople.value,
      method: 'POST',
      data: options
    }).then(res => res && res.data);
  }
  //小区设备统计
  countVillageDevice(options = {}) {
    return this.$httpRequest({
      url: community.countVillageDevice.value,
      method: 'POST',
      data: options
    }).then(res => res && res.data);
  }
  //小区数据资源统计
  countVillageResource(options = {}) {
    return this.$httpRequest({
      url: community.countVillageResource.value,
      method: 'POST',
      data: options
    }).then(res => res && res.data);
  }
  //获取社区7天人脸抓拍数
  countFace(options = {}) {
    return this.$httpRequest({
      url: community.countFace.value,
      method: 'POST',
      data: options
    }).then(res => res && res.data);
  }

  /**
   * 上传小区形象图片
   */
  uploadVillageImg(data) {
    return this.$httpRequest({
      url: community.uploadVillageImg.value,
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 添加小区
   */
  addVillage(data) {
    return this.$httpRequest({
      url: community.addVillage.value,
      method: 'POST',
      data,
      logInfo: {
        description: `新增【${data.villageName}】小区`,
        ...community.addVillage.logInfo[0]
      }
    }).then(res => res, err => err);
  }
  /**
   * 给小区分配设备
   */
  assignDevice(data) {
    return this.$httpRequest({
      url: community.assignDevice.value,
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 小区已分配设备列表
   */
  assignedDevice(data) {
    return this.$httpRequest({
      url: community.assignedDevice.value,
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 给小区移动组织归属
   */
  assignViillage(data, info) {
    let logInfo = {
      description: `小区【${info.areaName.join('、')}】从组织【${info.fromOrgName}】移动到组织【${info.toOrgName}】`,
      ...community.assignViillage.logInfo[0]
    };
    return this.$httpRequest({
      url: community.assignViillage.value,
      method: 'POST',
      data,
      logInfo
    }).then(res => res, err => err);
  }
  /**
   * 删除小区
   */
  deleteVillage(data) {
    return this.$httpRequest({
      url: community.deleteVillage.value.replace('<id>', data.id),
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 获取小区列表
   */
  queryVillages(data) {
    return this.$httpRequest({
      url: community.queryVillages.value,
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 重置小区
   */
  resetVillage(data, name) {
    return this.$httpRequest({
      url: community.resetVillage.value.replace('<id>', data.id),
      method: 'POST',
      data,
      logInfo: {
        description: `重置【${name}】小区`,
        ...community.resetVillage.logInfo[0]
      }
    }).then(res => res, err => err);
  }
  /**
   * 编辑小区基本信息
   */
  updateVillage(data) {
    return this.$httpRequest({
      url: community.updateVillage.value,
      method: 'POST',
      data,
      logInfo: {
        description: `编辑【${data.villageName}】小区`,
        ...community.updateVillage.logInfo[0]
      }
    }).then(res => res, err => err);
  }
  /**
   * 小区详情
   */
  villageDetail(data) {
    return this.$httpRequest({
      url: community.villageDetail.value.replace('<id>', data.id),
      method: 'POST',
      data
    }).then(res => res, err => err);
  }
  /**
   * 绑定aid列表查询
   */
  queryWaitingBindAids(data) {
    return this.$httpRequest({
      url: community.queryWaitingBindAids.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }
  /**
   * 小区车辆列表查询
   */
  queryRegisteredVehicle(data) {
    return this.$httpRequest({
      url: community.queryRegisteredVehicle.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }

  /**
   * 临时车辆列表查询
   */
  queryTempVehicle(data) {
    return this.$httpRequest({
      url: community.queryTempVehicle.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }

  /**
   * @desc 小区人脸抓拍查询
   * @param {Object} data
   */
  queryFaces(data) {
    BaseStore.action.setAction(community.queryFaces.actionName);
    return this.$httpRequest({
      url: community.queryFaces.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryFaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryFaces.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 小区人体抓拍查询
   * @param {Object} data
   */
  queryBodies(data) {
    BaseStore.action.setAction(community.queryBodies.actionName);
    return this.$httpRequest({
      url: community.queryBodies.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryBodies.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryBodies.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 小区门禁抓拍查询
   * @param {Object} data
   */
  queryAccessRecords(data) {
    BaseStore.action.setAction(community.queryAccessRecords.actionName);
    return this.$httpRequest({
      url: community.queryAccessRecords.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryAccessRecords.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryAccessRecords.actionName);
        return Promise.reject(e);
      });
  }

    /**
   * @desc 小区门禁抓拍查询
   * @param {Object} data
   */
  queryAccessRecordsForCommunity(data) {
    BaseStore.action.setAction(community.queryAccessRecordsForCommunity.actionName);
    return this.$httpRequest({
      url: community.queryAccessRecordsForCommunity.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryAccessRecordsForCommunity.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryAccessRecordsForCommunity.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 小区车辆详情查询
   * {vehiclePlateNum:'111'}
   */
  registeredVehicle(data) {
    return this.$httpRequest({
      url: community.registeredVehicle.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }
  /**
   * 临时车辆详情查询
   * {vehiclePlateNum:'鄂A3333'}
   */
  getTempVehicleDetail(data) {
    return this.$httpRequest({
      url: community.getTempVehicleDetail.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }
  /**
   * 小区车辆抓拍记录查询
   * {"vehiclePlateNumber":"鄂A111111",	"limit":10,"offset":0}
   */
  queryVehicleRecords(data) {
    return this.$httpRequest({
      url: community.queryVehicleRecords.value,
      method: 'POST',
      data
    }).then(res => res && res.data);
  }
  /**
   * 小区车辆常去地列表查询
   * { "vehiclePlateNumber":"","statisticPeroidType":0}
   */
  queryTrackCount(data) {
    return this.$httpRequest({
      url: community.queryTrackCount.value,
      method: 'POST',
      data
    }).then(res => res);
  }

  /**
   * 小区车辆常去地列表查询
   * {"vehiclePlateNumber":"","statisticPeroidType"?:0}
   */
  queryFrequenteds(data) {
    return this.$httpRequest({
      url: community.queryFrequenteds.value,
      method: 'POST',
      data
    }).then(res => res);
  }

  /**
   * 小区车辆常去地详情查询
   * {"vehiclePlateNumber":"","placeId":"21324","statisticPeroidType"?:0}
   */
  queryFrequentedDetails(data) {
    return this.$httpRequest({
      url: community.queryFrequentedDetails.value,
      method: 'POST',
      data
    }).then(res => res);
  }

  /**
   *  @desc 根据id查询门禁记录的详情
   *  @param {sting} id
   */
  queryAccessRecordsDetail(id) {
    BaseStore.action.setAction(community.queryAccessRecordsDetail.actionName);
    return this.$httpRequest({
      url: community.queryAccessRecordsDetail.value.replace('<id>', id),
      method: 'POST',
      data: { id }
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryAccessRecordsDetail.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryAccessRecordsDetail.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区车辆统计
   *  @param {object} data
   */
  countVillageVehicles(data) {
    BaseStore.action.setAction(community.countVillageVehicles.actionName);
    return this.$httpRequest({
      url: community.countVillageVehicles.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.countVillageVehicles.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.countVillageVehicles.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区车辆统计
   *  @param {object} data
   */
  queryCaptureVehiclesRecords(data) {
    BaseStore.action.setAction(community.queryCaptureVehiclesRecords.actionName);
    return this.$httpRequest({
      url: community.queryCaptureVehiclesRecords.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryCaptureVehiclesRecords.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryCaptureVehiclesRecords.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区房屋统计
   *  @param {object} data
   */
  housingStatistics(data) {
    BaseStore.action.setAction(community.housingStatistics.actionName);
    return this.$httpRequest({
      url: community.housingStatistics.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.housingStatistics.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.housingStatistics.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区人脸抓拍数量查询
   *  @param {object} data
   */
  countFaces(data) {
    BaseStore.action.setAction(community.countFaces.actionName);
    return this.$httpRequest({
      url: community.countFaces.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.countFaces.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.countFaces.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区人体抓拍数量查询
   *  @param {object} data
   */
  countBodies(data) {
    BaseStore.action.setAction(community.countBodies.actionName);
    return this.$httpRequest({
      url: community.countBodies.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.countBodies.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.countBodies.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区门禁记录数量查询
   *  @param {object} data
   */
  countAccessRecords(data) {
    BaseStore.action.setAction(community.countAccessRecords.actionName);
    return this.$httpRequest({
      url: community.countAccessRecords.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.countAccessRecords.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.countAccessRecords.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区车辆导入
   *  @param {object} data
   */
  uploadVehicleFile(data, id) {
    BaseStore.action.setAction(community.uploadVehicleFile.actionName);
    return this.$httpRequest({
      url: community.uploadVehicleFile.value.replace('<id>', id),
      method: 'POST',
      data,
      timeout: null
    })
      .then(res => {
        BaseStore.action.removeAction(community.uploadVehicleFile.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.uploadVehicleFile.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区录入车辆列表
   *  @param {object} data
   */
  queryEnteringVehicles(data) {
    BaseStore.action.setAction(community.queryEnteringVehicles.actionName);
    return this.$httpRequest({
      url: community.queryEnteringVehicles.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.queryEnteringVehicles.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryEnteringVehicles.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 小区录入车辆编辑
   *  @param {object} data
   */
  updateEnteringVehicles(data) {
    BaseStore.action.setAction(community.updateEnteringVehicles.actionName);
    return this.$httpRequest({
      url: community.updateEnteringVehicles.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.updateEnteringVehicles.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.updateEnteringVehicles.actionName);
        return Promise.reject(e);
      });
  }

  /**
   *  @desc 删除小区登记的车辆信息
   *  @param {object} data
   */
  deleteEnteringVehicles(data) {
    BaseStore.action.setAction(community.deleteEnteringVehicles.actionName);
    return this.$httpRequest({
      url: community.deleteEnteringVehicles.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(community.deleteEnteringVehicles.actionName);
        return res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.deleteEnteringVehicles.actionName);
        return Promise.reject(e);
      });
  }
  /**
   *  @desc 人员下的车辆信息
   *  @param {object} data
   */
  queryVehiclesByPersonId(data) {
    return this.$httpRequest({
      url: community.queryVehiclesByPersonId.value.replace('<id>', data.id),
      method: 'POST',
      data
    })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
  /**
   *  @desc 人员下的车辆信息
   *  @param {object} data
   */
  queryPassDeviceCount(data) {
    return this.$httpRequest({
      url: community.queryPassDeviceCount.value,
      method: 'POST',
      data
    })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  // 获取小区人员非脱敏
  queryRegisteredPeopleWithoutSensitiveData(options = {}) {
    BaseStore.action.setAction(community.queryRegisteredPeopleWithoutSensitiveData.value);
    return this.$httpRequest({
      url: community.queryRegisteredPeopleWithoutSensitiveData.value,
      method: 'POST',
      data: options
    })
      .then(res => {
        BaseStore.action.setAction(community.queryRegisteredPeopleWithoutSensitiveData.actionName);
        return res && res.data;
      })
      .catch(e => {
        BaseStore.action.removeAction(community.queryRegisteredPeopleWithoutSensitiveData.actionName);
        return Promise.reject(e);
      });
  }
}
export default new communityService();
