import Config from '../../config';
const { api, version } = Config;

export default {
  queryWifiDatas: {
    value: `${api}wifi/${version}/wifiDatas/queryWifiDatas`,
    label: '按条件查询wifi设备列表',
    actionName: 'queryWifiDatas'
  },
  queryWifiCaptureDevices: {
    value: `${api}wifi/${version}/queryWifiCaptureDevices`,
    label: '按条件查询wifi探针',
    actionName: 'queryWifiCaptureDevices'
  }
}