import { httpRequest } from "../http"
import wifi from "../url/baselib/wifi"

@httpRequest
class wifiService {
  /**
   * @desc 按条件查询wifi设备列表
   * @param {Object} data
   */
  queryWifiDatas(data) {
    BaseStore.action.setAction(wifi.queryWifiDatas.value);
    return this.$httpRequest({
      url: wifi.queryWifiDatas.value,
      method: 'post',
      data
    }).then(res => {
      BaseStore.action.removeAction(wifi.queryWifiDatas.actionName);
      return res;
    })
    .catch(e => {
      BaseStore.action.removeAction(wifi.queryWifiDatas.actionName);
      return Promise.reject(e);
    });
  }

  /**
   * @desc 按条件查询wifi探针
   * @param {Object} data
   */
  queryWifiCaptureDevices(data) {
    BaseStore.action.setAction(wifi.queryWifiCaptureDevices.value);
    return this.$httpRequest({
      url: wifi.queryWifiCaptureDevices.value,
      method: 'post',
      data
    }).then(res => {
      BaseStore.action.removeAction(wifi.queryWifiCaptureDevices.actionName);
      return res;
    })
    .catch(e => {
      BaseStore.action.removeAction(wifi.queryWifiCaptureDevices.actionName);
      return Promise.reject(e);
    });
  }
}

export default new wifiService()
