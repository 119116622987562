import { httpRequest } from "./http";
import device from "./url/device";
import moment from "moment";

@httpRequest
class DeviceService {
  /**
   * @desc 获取用户的所有设备 全局用
   * @param {*} data
   */
  queryUserDevices(data) {
    BaseStore.action.setAction(device.queryUserDevices.value);
    return this.$httpRequest({
      url: device.queryUserDevices.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryUserDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryUserDevices.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取用户的所有设备 全局用 NEW
   * @param {*} data
   */
  queryUserDevicesNew(data) {
    BaseStore.action.setAction(device.queryUserDevicesNew.value);
    return this.$httpRequest({
      url: device.queryUserDevicesNew.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryUserDevicesNew.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryUserDevicesNew.actionName);
        return Promise.reject(e);
      });
  }
  queryUserDevicesStatusNew(data) {
    BaseStore.action.setAction(device.queryUserDevicesStatusNew.value);
    return this.$httpRequest({
      url: device.queryUserDevicesStatusNew.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryUserDevicesStatusNew.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryUserDevicesStatusNew.actionName);
        return Promise.reject(e);
      });
  }
  // 查询用户所有设备的前端录像权限
  queryDeviceLocalRecordAbilityList(data) {
    BaseStore.action.setAction(device.queryDeviceLocalRecordAbilityList.value);
    return this.$httpRequest({
      url: device.queryDeviceLocalRecordAbilityList.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryDeviceLocalRecordAbilityList.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryDeviceLocalRecordAbilityList.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取用户的所有设备 设备管理用
   * @param {*} data
   */
  queryDevices(data) {
    BaseStore.action.setAction(device.queryDevices.value);
    return this.$httpRequest({
      url: device.queryDevices.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryDevices.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 按照类型统计设备数量
   * @param {*} data
   */
  countDeviceType(data) {
    BaseStore.action.setAction(device.countDeviceType.value);
    return this.$httpRequest({
      url: device.countDeviceType.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryUserDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryUserDevices.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 统计应用系统设备数量
   * @param {*} data
   */
  countOperationCenterDevices(data) {
    BaseStore.action.setAction(device.countOperationCenterDevices.value);
    return this.$httpRequest({
      url: device.countOperationCenterDevices.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.countOperationCenterDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.countOperationCenterDevices.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 统计组织的在线离线设备数量
   * @param {*} data
   */
  countOrganizationDeviceStatus(data) {
    BaseStore.action.setAction(device.countOrganizationDeviceStatus.value);
    return this.$httpRequest({
      url: device.countOrganizationDeviceStatus.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.countOrganizationDeviceStatus.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.countOrganizationDeviceStatus.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 统计场所的在线离线设备数量
   * @param {*} data
   */
  countPlaceDeviceStatus(data) {
    BaseStore.action.setAction(device.countPlaceDeviceStatus.value);
    return this.$httpRequest({
      url: device.countPlaceDeviceStatus.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.countPlaceDeviceStatus.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.countPlaceDeviceStatus.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询设备详细信息
   * @param {*} id
   */
  queryDeviceInfo(id, isSoldier = false) {
    const { deviceName } = BaseStore.device.getDeviceByIdOrCid(id) || {};
    BaseStore.action.setAction(device.deviceInfo.value);
    return this.$httpRequest({
      url: device.deviceInfo.value.replace("<id>", id),
      method: "post",
      data: { id },
      logInfo: isSoldier
        ? {}
        : {
            description: `查看设备【${deviceName}】信息`,
            ...device.deviceInfo.logInfo[0]
          }
    })
      .then(res => {
        BaseStore.action.removeAction(device.deviceInfo.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.deviceInfo.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询设备详细信息 cid
   * @param {*} cid
   */
  queryDeviceInfoByCid(cid) {
    BaseStore.action.setAction(device.deviceInfoByCid.value);
    return this.$httpRequest({
      url: device.deviceInfoByCid.value.replace("<id>", cid),
      method: "post",
      data: { cid }
    })
      .then(res => {
        BaseStore.action.removeAction(device.deviceInfoByCid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.deviceInfoByCid.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 根据组织查询设备列表
   * @param {*} id
   */
  deviceListByOrganization(data, orgId) {
    const organizationId = orgId || data.orgIds[0];
    BaseStore.action.setAction(device.deviceInfoByCid.value);
    return this.$httpRequest({
      url: device.deviceListByOrganization.value.replace("<id>", organizationId),
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.deviceInfoByCid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.deviceInfoByCid.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询设备分组列表
   */
  queryDeviceGroup() {
    BaseStore.action.setAction(device.queryDeviceGroup.value);
    return this.$httpRequest({
      url: device.queryDeviceGroup.value,
      method: "post"
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryDeviceGroup.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryDeviceGroup.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 更新设备
   * @param {*} data
   */
  updateDevice(data) {
    BaseStore.action.setAction(device.updateDevice.value);
    data.otherInfo = data.otherInfo ? data.otherInfo : { deviceType: data.deviceType, deviceName: data.deviceName };
    return this.$httpRequest({
      url: device.updateDevice.value.replace("<id>", data.id),
      method: "post",
      data,
      logInfo: {
        description: `编辑${data.otherInfo && data.otherInfo.deviceType != 100605 ? "设备" : "单兵"}【${data.otherInfo.deviceName ||
          data.otherInfo.cameraInfo.name}】信息`,
        ...device.updateDevice.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateDevice.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateDevice.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 更新设备GEO
   * @param {*} data
   */
  updateDeviceGeoAndPlace(data) {
    const { deviceName } = BaseStore.device.getDeviceByIdOrCid(data.id) || {};
    BaseStore.action.setAction(device.updateDeviceGeoAndPlace.value);
    return this.$httpRequest({
      url: device.updateDeviceGeoAndPlace.value.replace("<id>", data.id),
      method: "post",
      data,
      logInfo: {
        description: `修改设备【${deviceName}】经纬度为：[${data.geoBean.longitude},${data.geoBean.latitude}]`,
        ...device.updateDeviceGeoAndPlace.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateDeviceGeoAndPlace.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateDeviceGeoAndPlace.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 根据拉流状态判断设备状态不对时，向后台反馈设备状态
   * @param {*} deviceId
   * @param {*} deviceStatus
   */
  updateDeviceStatus(deviceId, deviceStatus) {
    BaseStore.action.setAction(device.updateDeviceStatus.value);
    return this.$httpRequest({
      url: device.updateDeviceStatus.value,
      method: "POST",
      data: {
        deviceId,
        onLine: deviceStatus
      }
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateDeviceStatus.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateDeviceStatus.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 分配设备到应用系统/从应用系统解除分配
   * @param {*} data
   */
  updateOperationCenterDevices(data) {
    BaseStore.action.setAction(device.updateOperationCenterDevices.value);
    return this.$httpRequest({
      url: device.updateOperationCenterDevices.value.replace("<id>", data.operationCenterId),
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateOperationCenterDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateOperationCenterDevices.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 分配设备到组织/从组织解除分配
   * @param {*} data
   */
  updateOrganizationDevicesBatch(data) {
    BaseStore.action.setAction(device.updateOrganizationDevicesBatch.value);
    return this.$httpRequest({
      url: device.updateOrganizationDevicesBatch.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateOrganizationDevicesBatch.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateOrganizationDevicesBatch.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 设备导出
   * @param {*} organizationId
   */
  exportDevice(data) {
    let organizationId = data.organizationId;
    let time = moment().format("YYYYMMDD");
    BaseStore.action.setAction(device.exportDevice.value);
    return fetch(device.exportDevice.value.replace("<id>", organizationId), {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: Utils.getCache("token", "session")
      }
    })
      .then(res => res.blob())
      .then(blob => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${data.orgName}组织部门设备列表_${time}.xlsx`;
        document.body.appendChild(link);
        link.click();
        // window.URL.revokeObjectURL(link.href);
        BaseStore.action.removeAction(device.exportDevice.actionName);
        return Promise.resolve();
      })
      .catch(e => {
        BaseStore.action.removeAction(device.exportDevice.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 条件组合查询设备数量
   */

  countDevices(data) {
    BaseStore.action.setAction(device.countDevices.value);
    return this.$httpRequest({
      url: device.countDevices.value,
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.countDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.countDevices.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 根据应用系统查询设备
   */

  queryDevicesByOperationCenter(data) {
    BaseStore.action.setAction(device.queryDevicesByOperationCenter.value);
    return this.$httpRequest({
      url: device.queryDevicesByOperationCenter.value.replace("<id>", data.operationCenterId),
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.queryDevicesByOperationCenter.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.queryDevicesByOperationCenter.actionName);
        return Promise.reject(e);
      });
  }

  
  /**
   * @desc 根据应用系统查询设备
   */

  updateDeviceGeo(data) {
    BaseStore.action.setAction(device.updateDeviceGeo.value);
    return this.$httpRequest({
      url: device.updateDeviceGeo.value.replace("<id>", data.id),
      method: "post",
      data
    })
      .then(res => {
        BaseStore.action.removeAction(device.updateDeviceGeo.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(device.updateDeviceGeo.actionName);
        return Promise.reject(e);
      });
  }
}

export default new DeviceService();
