import { httpRequest } from './http';
import privilege from './url/privilege';
import _ from 'lodash';

@httpRequest
class PrivilegeService {
  queryUserPrivileges(userId){
    BaseStore.action.setAction(privilege.userPrivilege.actionName);
    // 管理员不传 menuType
    // 1：web 2:app
    const menuType = BaseStore.user.userInfo.userType !== 100701 ? 1 : undefined;
    return this.$httpRequest({
      method: 'post',
      url: privilege.userPrivilege.value,
      data: { userId, menuType }
    })
      .then(res => {
        BaseStore.action.removeAction(privilege.userPrivilege.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(privilege.userPrivilege.actionName);
        return Promise.reject(e);
      });
  }
  queryRolePrivileges(id){
    BaseStore.action.setAction(privilege.rolePrivilege.actionName);
    return this.$httpRequest({
      method: 'post',
      url: privilege.rolePrivilege.value.replace('<id>', id),
      data: { id }
    })
      .then(res => {
        BaseStore.action.removeAction(privilege.rolePrivilege.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(privilege.rolePrivilege.actionName);
        return Promise.reject(e);
      });
  }
}
export default new PrivilegeService();
