import Config from '../config';
const { api, version } = Config;

export default {
  // comEntryModule: {
  //   code: 106700,
  //   text: "社区管理"
  // },
  // enterComEntryModule: {
  //   code: 106799,
  //   parent: 106700,
  //   text: "进入社区管理界面",
  //   moduleName: "communityOverview"
  // },
  comEntryModule: {
    code: 107900,
    text: '社区总览'
  },
  enterComEntryModule: {
    code: 107999,
    parent: 107900,
    text: '进入社区总览界面',
    moduleName: 'communityOverview'
  },
  villageModule: {
    code: 107000,
    text: '小区管理'
  },
  enterVillageModule: {
    text: '进入小区管理界面',
    code: 107099,
    parent: 107000,
    moduleName: 'villageView'
  },
  comResdModule: {
    code: 106800,
    text: '登记人员管理'
  },
  enterComResdModule: {
    code: 106899,
    parent: 106800,
    text: '进入登记人员管理界面',
    moduleName: 'communityRegistered'
  },
  comFlowModule: {
    code: 106900,
    text: '未登记人员管理'
  },
  enterComFlowModule: {
    code: 106999,
    parent: 106900,
    text: '进入未登记人员管理界面',
    moduleName: 'communityUnRegistered'
  },
  // 小区
  statisticsList: {
    value: `${api}community/${version}/statisticsList`,
    label: '社区总览列表',
    actionName: 'statisticsList'
  },
  queryRegisteredPeople: {
    value: `${api}community/${version}/queryRegisteredPeople`,
    label: '已登记人口列表查询',
    actionName: 'queryRegisteredPeople',
    logInfo: [
      {
        code: 106801,
        parent: 106800,
        text: '查询已登记人口信息'
      }
    ]
  },
  queryUnregisteredPeople: {
    value: `${api}community/${version}/queryUnregisteredPeople`,
    label: '未登记人口列表查询',
    actionName: 'queryUnregisteredPeople',
    logInfo: [
      {
        code: 106901,
        parent: 106900,
        text: '查询未登记人口信息'
      }
    ]
  },
  updatePeopleFocus: {
    value: `${api}community/${version}/updatePeopleFocus`,
    label: '添加取消关注',
    actionName: 'updatePeopleFocus'
  },
  uploadVillageImg: {
    value: `${api}community/${version}/uploadVillageImg`,
    label: '上传小区形象图片'
  },
  addVillage: {
    value: `${api}community/${version}/village/addVillage`,
    label: '添加小区',
    logInfo: [
      {
        code: 107004,
        parent: 107000,
        text: '添加小区'
      }
    ]
  },
  assignDevice: {
    value: `${api}community/${version}/village/assignDevice`,
    label: '给小区分配设备'
  },
  assignedDevice: {
    value: `${api}community/${version}/village/assignedDevice`,
    label: '小区已分配设备列表'
  },
  assignViillage: {
    value: `${api}community/${version}/village/assignViillage`,
    label: '给小区移动组织归属',
    logInfo: [
      {
        code: 104306,
        parent: 104300,
        text: '分配小区'
      }
    ]
  },
  deleteVillage: {
    value: `${api}community/${version}/village/deleteVillage/<id>`,
    label: '删除小区'
  },
  queryVillages: {
    value: `${api}community/${version}/village/queryVillages`,
    label: '获取小区列表'
  },
  resetVillage: {
    value: `${api}community/${version}/village/resetVillage/<id>`,
    label: '重置小区',
    logInfo: [
      {
        code: 107003,
        parent: 107000,
        text: '重置小区'
      }
    ]
  },
  updateVillage: {
    value: `${api}community/${version}/village/updateVillage`,
    label: '编辑小区基本信息',
    logInfo: [
      {
        code: 107001,
        parent: 107000,
        text: '编辑小区'
      }
    ]
  },
  villageDetail: {
    value: `${api}community/${version}/village/villages/<id>`,
    label: '小区详情'
  },
  countVillagePeople: {
    value: `${api}community/${version}/countVillagePeople`,
    label: '小区人员统计'
  },
  countVillageDevice: {
    value: `${api}community/${version}/countVillageDevice`,
    label: '小区设备统计'
  },
  countVillageResource: {
    value: `${api}community/${version}/countVillageResource`,
    label: '小区数据资源统计(人脸/人体/机动车)'
  },
  countFace: {
    value: `${api}community/${version}/countFace`,
    label: '获取社区7天人脸抓拍数'
  },
  queryWaitingBindAids: {
    value: `${api}community/${version}/people/queryWaitingBindAids`,
    label: '绑定aid列表查询'
  },
  queryRegisteredVehicle: {
    value: `${api}community/${version}/vehicle/queryRegisteredVehicles`,
    label: '小区车辆列表查询'
  },
  queryFaces: {
    value: `${api}community/${version}/queryFaces `,
    label: '小区人脸抓拍查询'
  },
  countFaces: {
    value: `${api}community/${version}/countFaces `,
    label: '小区人脸抓拍数量查询'
  },
  queryBodies: {
    value: `${api}community/${version}/queryBodies `,
    label: '小区人体抓拍查询'
  },
  countBodies: {
    value: `${api}community/${version}/countBodies `,
    label: '小区人体抓拍数量查询'
  },
  queryAccessRecords: {
    value: `${api}community/${version}/accessRecord/queryAccessRecords `,
    label: '门禁记录列表查询'
  },
  queryAccessRecordsForCommunity: {
    value: `${api}community/${version}/accessRecord/queryAccessRecordsForCommunity `,
    label: '门禁记录列表查询（云社区总览）'
  },
  countAccessRecords: {
    value: `${api}community/${version}/accessRecordStatistics/countAccessRecords `,
    label: '小区门禁记录数量查询'
  },
  registeredVehicle: {
    value: `${api}community/${version}/vehicle/vehicleDetails`,
    label: '小区车辆详情查询'
  },
  queryVehicleRecords: {
    value: `${api}community/${version}/vehicle/queryVehiclesAllRecords`,
    label: '小区车辆抓拍记录查询'
  },
  queryFrequenteds: {
    value: `${api}community/${version}/vehicle/queryFrequenteds`,
    label: '小区车辆常去地列表查询'
  },
  queryFrequentedDetails: {
    value: `${api}community/${version}/vehicle/queryFrequentedDetails`,
    label: '小区车辆常去地详情查询'
  },
  queryAccessRecordsDetail: {
    value: `${api}community/${version}/accessRecord/accessRecords/<id>`,
    label: '根据id查询门禁记录的详情',
    actionName: 'queryAccessRecordsDetail'
  },
  queryTempVehicle: {
    value: `${api}community/${version}/tempVehicle/queryTempVehicle`,
    label: '临时车辆列表查询'
  },
  getTempVehicleDetail: {
    value: `${api}community/${version}/tempVehicle/getTempVehicleDetail`,
    label: '临时车辆详情查询'
  },
  queryTrackCount: {
    value: `${api}community/${version}/vehicle/queryTrackCount`,
    label: '查询车辆轨迹聚合'
  },
  queryVehiclesByPersonId: {
    value: `${api}community/${version}/vehicle/queryVehiclesByPersonId/<id>`,
    label: '根据社区人员id查询该人名下的车辆信息'
  },
  countVillageVehicles: {
    value: `${api}community/${version}/vehicle/countVillageVehicles`,
    label: '小区车辆统计'
  },
  queryCaptureVehiclesRecords: {
    value: `${api}community/${version}/vehicle/queryCaptureVehiclesRecords`,
    label: '小区车辆抓拍记录'
  },
  housingStatistics: {
    value: `${api}community/${version}/housing/statistics`,
    label: '社区楼栋房屋统计'
  },
  uploadVehicleFile: {
    value: `${api}community/${version}/vehicle/uploadVehicleFile/<id>`,
    label: '小区车辆导入'
  },
  queryEnteringVehicles: {
    value: `${api}community/${version}/vehicle/queryEnteringVehicles`,
    label: '小区录入车辆列表'
  },
  updateEnteringVehicles: {
    value: `${api}community/${version}/vehicle/updateEnteringVehicles`,
    label: '小区录入车辆编辑'
  },
  deleteEnteringVehicles: {
    value: `${api}community/${version}/vehicle/deleteEnteringVehicles`,
    label: '删除小区登记的车辆信息'
  },
  queryPassDeviceCount: {
    value: `${api}community/${version}/vehicle/queryPassDeviceCount`,
    label: '轨迹统计信息'
  },
  queryRegisteredPeopleWithoutSensitiveData: {
    value: `${api}community/${version}/queryRegisteredPeopleWithoutSensitiveData`,
    label: '获取小区人员非脱敏'
  }
};
