import { httpRequest } from './http';
import person from './url/person';

@httpRequest
class PersonService {
  /**
   * @desc 新增实有人员
   * @param {Object} data
   */
  addPerson(data) {
    BaseStore.action.setAction(person.addPerson.actionName);
    return this.$httpRequest({
      url: person.addPerson.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.addPerson.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.addPerson.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 批量导入实有人员信息
   * @param {Object} data
   */
  importPersons(data) {
    BaseStore.action.setAction(person.importPersons.actionName);
    return this.$httpRequest({
      url: person.importPersons.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `【${data.villageName}】小区人员信息录入`,
        ...person.importPersons.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.importPersons.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.importPersons.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 删除单个实有人员
   * @param {Any} id
   */
  deletePerson(id) {
    BaseStore.action.setAction(person.deletePerson.actionName);
    return this.$httpRequest({
      url: person.deletePerson.value.replace('<id>', id),
      method: 'POST',
      data: { id }
    })
      .then(res => {
        BaseStore.action.removeAction(person.deletePerson.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.deletePerson.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 批量删除实有人员
   * @param {Object} data
   */
  deletePersons(data) {
    BaseStore.action.setAction(person.deletePersons.actionName);
    return this.$httpRequest({
      url: person.deletePersons.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.deletePersons.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.deletePersons.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 修改实有人员信息
   * @param {Object} data
   */
  updatePerson(data) {
    BaseStore.action.setAction(person.updatePerson.actionName);
    return this.$httpRequest({
      url: person.updatePerson.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.updatePerson.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.updatePerson.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 根据条件查询人员
   * @param {Object} data
   */
  queryPersons(data = {}) {
    BaseStore.action.setAction(person.queryPersons.actionName);
    return this.$httpRequest({
      url: person.queryPersons.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `通过【${Array.isArray(data.keywords) && data.keywords.length > 0 ? `关键字${data.keywords.join()}` : ''}${data.pictureUrl && `图片${data.pictureUrl}`} ${
          Array.isArray(data.recommends) && data.recommends.length > 0 ? `标签${data.recommends.map(v => v.description).join()}` : ''
        }
        】查询人员`,
        ...person.queryPersons.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryPersons.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryPersons.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取人员相似的AID
   * @param {Object} data
   */
  querySimilarVids(data) {
    BaseStore.action.setAction(person.querySimilarVids.actionName);
    return this.$httpRequest({
      url: person.querySimilarVids.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.querySimilarVids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.querySimilarVids.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据关键字返回推荐信息
   * @param {Object} data
   */
  searchPersonByKeywords(data) {
    BaseStore.action.setAction(person.searchPersonByKeywords.actionName);
    return this.$httpRequest({
      url: person.searchPersonByKeywords.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.searchPersonByKeywords.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.searchPersonByKeywords.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 人员档案上传图片搜索
   * @param {Object} data
   */
  uploadPersonPicture(data) {
    BaseStore.action.setAction(person.uploadPersonPicture.actionName);
    return this.$httpMultiPart({
      url: person.uploadPersonPicture.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `上传图片URL以图搜人`,
        ...person.uploadPersonPicture.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.uploadPersonPicture.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.uploadPersonPicture.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 根据id查询人员的基本信息
   * @param {Object} data
   */
  getPersonById(data) {
    let logID = undefined;
    if (data.personId) {
      logID = data.personId;
    } else {
      logID = data.aid;
    }
    BaseStore.action.setAction(person.getPersonById.actionName);
    return this.$httpRequest({
      url: data.url,
      method: 'POST',
      logInfo: {
        description: `查看人员【${logID}】档案详情`,
        ...person.getPersonById.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonById.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonById.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询实有人员的证件号码
   * @param {Object} data
   */
  getPersonIdentityCardNumber(data) {
    BaseStore.action.setAction(person.getPersonIdentityCardNumber.actionName);
    return this.$httpRequest({
      url: person.getPersonIdentityCardNumber.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonIdentityCardNumber.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonIdentityCardNumber.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询实有人员的手机号码
   * @param {Object} data
   */
  getPersonMobile(data) {
    BaseStore.action.setAction(person.getPersonMobile.actionName);
    return this.$httpRequest({
      url: person.getPersonMobile.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonMobile.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonMobile.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询单个人员各种外观类型下具体的标签及出现次数
   * @param {Object} data
   */
  getPersonDetailAppearance(data) {
    BaseStore.action.setAction(person.getPersonDetailAppearance.actionName);
    return this.$httpRequest({
      // url: person.getPersonDetailAppearance.value,
      url: data,
      // data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonDetailAppearance.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonDetailAppearance.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取单个人员常去地及在常去地出现的次数
   * @param {Object} data
   */
  getPersonFrequentedPlaces(url, data, option) {
    BaseStore.action.setAction(person.getPersonFrequentedPlaces.actionName);
    return this.$httpRequest({
      url,
      method: 'POST',
      data: option
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonFrequentedPlaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonFrequentedPlaces.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取实有人员的门禁出入记录
   * @param {Object} data
   */
  getPersonAccessRecords(url, data) {
    BaseStore.action.setAction(person.getPersonAccessRecords.actionName);
    return this.$httpRequest({
      url,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonAccessRecords.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonAccessRecords.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取实有人员同屋关系
   * @param {Object} data
   */
  queryPersonRoommates(data) {
    BaseStore.action.setAction(person.getPersonRoommates.actionName);
    return this.$httpRequest({
      // url: person.getPersonRoommates.value,
      url: data,
      // data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonRoommates.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonRoommates.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取单个人员的同行关系
   * @param {Object} data
   */
  queryPersonAccompanies(url, data = {}) {
    BaseStore.action.setAction(person.getPersonAccompanies.actionName);
    return this.$httpRequest({
      url,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonAccompanies.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonAccompanies.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取两个人员的详细同行信息
   * @param {Object} data
   */
  queryDetailAccompany(url, data, ID) {
    BaseStore.action.setAction(person.getDetailAccompany.actionName);
    return this.$httpRequest({
      url,
      data: data,
      method: 'POST',
      logInfo: {
        description: `查看人员【${ID}】的同行关系`,
        ...person.getDetailAccompany.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.getDetailAccompany.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getDetailAccompany.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员指定时间范围内一天各个时间段的出行规律以及平均一天出行规律
   * @param {Object} data
   */
  queryTravelRuleInOneDay(url, data) {
    BaseStore.action.setAction(person.queryTravelRuleInOneDay.actionName);
    return this.$httpRequest({
      url,
      data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryTravelRuleInOneDay.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryTravelRuleInOneDay.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员按天统计的出行规律
   * @param {Object} data
   */
  queryTravelRuleByDay(url, data) {
    BaseStore.action.setAction(person.queryTravelRuleByDay.actionName);
    return this.$httpRequest({
      url,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryTravelRuleByDay.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryTravelRuleByDay.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员每星期或每月的平均出行规律
   * @param {Object} data
   */
  queryAverageTravelRule(url, data) {
    BaseStore.action.setAction(person.queryAverageTravelRule.actionName);
    return this.$httpRequest({
      url,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryAverageTravelRule.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryAverageTravelRule.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员的落脚点信息
   * @param {Object} data
   */
  queryFootholds(data) {
    BaseStore.action.setAction(person.queryFootholds.actionName);
    return this.$httpRequest({
      url: person.queryFootholds.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryFootholds.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryFootholds.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员最近出现时间/最近出现地点
   * @param {Object} data
   */
  queryRecentAppearance(url) {
    return this.$httpRequest({
      url,
      method: 'POST'
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员首次出现时间/首次出现地点
   * @param {Object} data
   */
  queryFirstAppearance(url) {
    return this.$httpRequest({
      url,
      method: 'POST'
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取多个AID人员的照片信息
   * @param {Object} data
   */
  queryAidsPicture(data) {
    BaseStore.action.setAction(person.getAidsPicture.actionName);
    return this.$httpRequest({
      url: person.getAidsPicture.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getAidsPicture.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getAidsPicture.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 给人员打标签
   * @param {Object} data
   */
  addTags(data = {}) {
    let tags = [];
    if (data.addTagCodes) {
      let labelList = [...data.addTagCodes];
      labelList = [
        ...new Set(
          labelList.map(v => {
            if (v) {
              return parseInt(v / 10) * 10;
            }
          })
        )
      ];
      labelList = labelList.map(v => v + '');
      let dictLabel = Dict.typeCode.filter(v => labelList.indexOf(v.code) > -1);
      let arr = [];
      dictLabel.map(v => {
        arr = arr.concat(Dict.getDict(v.name));
      });
      tags = data.addTagCodes.map(v => arr.find(item => item.value == v) && arr.find(item => item.value == v).label);
    }
    BaseStore.action.setAction(person.addTags.actionName);
    return this.$httpRequest({
      url: person.addTags.value,
      data: data,
      method: 'POST',
      logInfo: {
        description: `编辑人员标签为【${tags.join()}】`,
        ...person.addTags.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(person.addTags.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.addTags.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员标签
   * @param {Object} data
   */
  queryPersonTags(data) {
    BaseStore.action.setAction(person.queryPersonTags.actionName);
    return this.$httpRequest({
      // url: person.queryPersonTags.value,
      url: data,
      // data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryPersonTags.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryPersonTags.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 设置/取消人员关注
   * @param {Object} data
   */
  setOrCancelFocus(data) {
    BaseStore.action.setAction(person.setOrCancelFocus.actionName);
    return this.$httpRequest({
      url: person.setOrCancelFocus.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.setOrCancelFocus.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.setOrCancelFocus.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员关注状态
   * @param {Object} data
   */
  getFocusInfos(data) {
    BaseStore.action.setAction(person.getFocusInfos.actionName);
    return this.$httpRequest({
      // url: person.getFocusInfos.value,
      // data: data,
      url: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getFocusInfos.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getFocusInfos.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询人员已关联到的AID列表
   * @param {Object} data
   */
  queryRelationVids(data) {
    BaseStore.action.setAction(person.queryRelationVids.actionName);
    return this.$httpRequest({
      url: person.queryRelationVids.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryRelationVids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryRelationVids.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 设置人员关联AID
   * @param {Object} data
   */
  addRelationVids(data) {
    BaseStore.action.setAction(person.addRelationVids.actionName);
    return this.$httpRequest({
      url: person.addRelationVids.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.addRelationVids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.addRelationVids.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 实有人员批量导入文件上传
   * @param {Object} file
   */
  uploadPersonsFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileSize', file.size);
    formData.append('fileName', file.name);
    BaseStore.action.setAction(person.uploadPersonsFile.actionName);
    return this.$httpMultiPart({
      url: person.uploadPersonsFile.value,
      data: formData,
      method: 'POST',
      timeout: null,
    })
      .then(res => {
        BaseStore.action.removeAction(person.uploadPersonsFile.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.uploadPersonsFile.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 录入人员列表
   * @param {Object} data
   */
  listCommunityPersons(data) {
    BaseStore.action.setAction(person.listCommunityPersons.actionName);
    return this.$httpRequest({
      url: person.listCommunityPersons.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.listCommunityPersons.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.listCommunityPersons.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取用户权限下的人员档案中的人员数
   * @param {Object} data
   */
  getPersonCount(data) {
    BaseStore.action.setAction(person.getPersonCount.actionName);
    return this.$httpRequest({
      url: person.getPersonCount.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.getPersonCount.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.getPersonCount.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询虚拟人员对应详情的参数
   * @param {Object} data
   */
  queryAidDetail(data) {
    BaseStore.action.setAction(person.queryAidDetail.actionName);
    return this.$httpRequest({
      url: person.queryAidDetail.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryAidDetail.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryAidDetail.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 通过aid查询是否有绑定personId
   * @param {Object} data
   */
  queryRecentAppearanceByAids(data) {
    BaseStore.action.setAction(person.queryRecentAppearanceByAids.actionName);
    return this.$httpRequest({
      url: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryRecentAppearanceByAids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryRecentAppearanceByAids.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 获取人员的关联信息
   * @param {Object} data
   */
  queryPersonBindInfo(data) {
    BaseStore.action.setAction(person.queryPersonBindInfo.actionName);
    return this.$httpRequest({
      url: person.queryPersonBindInfo.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryPersonBindInfo.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryPersonBindInfo.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询虚拟人员人体资源列表
   * @param {Object} data
   */
  queryBodiesByAids(data) {
    BaseStore.action.setAction(person.queryBodiesByAids.actionName);
    return this.$httpRequest({
      url: person.queryBodiesByAids.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(person.queryBodiesByAids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(person.queryBodiesByAids.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询aid经过设备次数
   * @param {Object} data
   */
  queryPassDeviceCount(url, data) {
    return this.$httpRequest({
      url,
      data: data,
      method: 'POST'
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询aid与设备的关系
   * @param {Object} data
   */
  queryAidAndCidRelation(url) {
    return this.$httpRequest({
      url,
      method: 'POST'
    })
  }
  getTogetherByFeature(data){
    return this.$httpRequest({
      url: person.getTogetherByFeature.value,
      data: data,
      method: 'POST'
    })
  }
  getAidAndAidTogetherDetail(data){
    return this.$httpRequest({
      url: person.getAidAndAidTogetherDetail.value,
      data: data,
      method: 'POST'
    })
  }
}

export default new PersonService();
