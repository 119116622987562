import Config from '../../config';
const { api, version } = Config;

export default {
	vehicleLibModule: {
    text: '机动车图库',
    code: 105600,
  },
  enterVehicleLibModule: {
    text: '进入机动车图库界面',
    code: 105699,
    parent: 105600,
    moduleName: 'vehicleLibrary',
  },
  nonVehicleLibModule: {
    text: '非机动车图库',
    code: 107600,
  },
  enterNonVehicleLibModule: {
    text: '进入非机动车图库界面',
    code: 107699,
    parent: 107600,
    moduleName: 'nonVehicleLibrary',
  },
  carDetailModule: {
    code: 105601,
    parent: 105600,
    text: '查看机动车抓拍图片',
    moduleName: 'carDetailModule'
  },
  nonVehicleDetailModule: {
    code: 107601,
    parent: 107600,
    text: '查看非机动车抓拍图片',
    moduleName: 'nonVehicleDetailModule'
  },
  queryPassRecords: {
    value: `${api}vehicle/${version}/queryPassRecords`,
    label: '机动车图库列表',
    actionName: 'queryPassRecords',
  },
  uploadImg: {
    value: `${api}vehicle/${version}/img/uploadImg`,
    label: '上传机动车以图搜图图片',
    actionName: 'passUploadImg',
  },
  getFeature: {
    value: `${api}vehicle/${version}/getFeatures`,
    label: 'Url车辆特征值提取',
    actionName: 'getFeature',
  },
  queryVehiclePictures: {
    value: `${api}vehicle/${version}/queryVehiclePictures`,
    label: '根据图片搜索机动车图片列表',
    actionName: 'queryVehiclePictures',
    logInfo: [
      {
        code: 105602,
        parent: 105600,
        text: '机动车以图搜图',
      },
      {
        code: 107602,
        parent: 107600,
        text: '非机动车以图搜图',
      }
    ],
  },
}