import config from '../config'
const { api, version } = config

export default {
  moniteePersonnelRealAlarmModule: {
    text: '警情处理',
    code: 105800
  },
  enterMoniteePersonnelRealAlarmModule: {
    text: '进入警情处理界面',
    code: 105899,
    parent: 105800,
    moduleName: 'personnelRealAlarm'
  },
  moniteeFaceAlarmModule: {
    text: '重点人员告警处理',
    code: 105200
  },
  enterMoniteeFaceAlarmModule: {
    text: '进入重点人员布控告警处理界面',
    code: 105299,
    parent: 105200,
    moduleName: 'keyPersonnelHistory'
  },
  moniteeOutsidersAlarmModule: {
    text: '外来人员告警处理',
    code: 105900
  },
  enterMoniteeOutsidersAlarmModule: {
    text: '进入外来人员布控告警处理界面',
    code: 105999,
    parent: 105900,
    moduleName: 'outsiderHistory'
  },
  moniteeAIOAlarmModule: {
    text: '专网套件告警处理',
    code: 107200
  },
  enterMoniteeAIOAlarmModule: {
    text: '进入专网套件布控告警处理界面',
    code: 107299,
    parent: 107200,
    moduleName: 'privateNetHistory'
  },
  moniteePhantomRealAlarmModule: {
    text: '事件处理',
    code: 106400
  },
  enterMoniteePhantomRealAlarmModule: {
    text: '进入事件处理界面',
    code: 106499,
    parent: 106400,
    moduleName: 'eventRealNotify'
  },
  moniteePhantomAlarmModule: {
    text: '人员入侵历史提醒',
    code: 106500
  },
  enterMoniteePhantomAlarmModule: {
    text: '进入人员入侵历史提醒界面',
    code: 106599,
    parent: 106500,
    moduleName: 'eventHistoryNotify'
  },
  vehicleAlarmModule: {
    text: '重点车辆告警',
    code: 106500
  },
  entervehicleAlarmModule: {
    text: '进入重点车辆告警界面',
    code: 106599,
    parent: 106500,
    moduleName: 'vehicleAlarm'
  },
  vehicleTemporaryAlarmModule: {
    text: '外来车辆告警',
    code: 106500
  },
  entervehicleTemporaryAlarmModule: {
    text: '进入外来车辆告警界面',
    code: 106599,
    parent: 106500,
    moduleName: 'vehicleTemporaryAlarm'
  },
  queryAlarmResults: {
    value: `${api}alarm/${version}/alarmResult/queryAlarmResults`,
    label: '获取警情列表',
    actionName: 'queryAlarmResults'
  },

  countAlarmResults: {
    value: `${api}alarm/${version}/alarmResult/countAlarmResults`,
    label: '获取警情列表',
    actionName: 'countAlarmResults'
  },

  alarmResults: {
    value: `${api}alarm/v2/alarmResult/alarmResults/<id>`,
    label: '查看历史警情详情',
    actionName: 'alarmResults',
    logInfo: [
      {
        type: 'personnelRealAlarm',
        code: 105801,
        parent: 105800,
        text: '查看告警信息'
      },
      {
        type: 'faceAlarm',
        code: 105203,
        parent: 105200,
        text: '查看告警信息'
      },
      {
        type: 'outsidersAlarm',
        code: 105901,
        parent: 105900,
        text: '查看告警信息'
      },
      {
        type: 'AIOAlarm',
        code: 107201,
        parent: 107200,
        text: '查看告警信息'
      },
      {
        type: 'phantomRealAlarm',
        code: 106401,
        parent: 106400,
        text: '查看事件处理信息'
      },
      {
        type: 'phantomAlarm',
        code: 106501,
        parent: 106500,
        text: '查看事件处理信息'
      }
    ]
  },
  handleAlarmResult: {
    value: `${api}alarm/${version}/alarmResult/handleAlarmResult`,
    label: '设置警情有效性',
    logInfo: [
      {
        type: 'personnelRealAlarm',
        code: 105802,
        parent: 105800,
        text: '处理告警信息'
      },
      {
        type: 'faceAlarm',
        code: 105202,
        parent: 105200,
        text: '处理告警信息'
      },
      {
        type: 'outsidersAlarm',
        code: 105905,
        parent: 105900,
        text: '处理告警信息'
      },
      {
        type: 'phantomRealAlarm',
        code: 106405,
        parent: 106400,
        text: '处理事件信息'
      },
      {
        type: 'phantomAlarm',
        code: 106505,
        parent: 106500,
        text: '处理事件信息'
      }
    ]
  },
  getTaskAlarms: {
    value: `${api}alarm/${version}/getTaskAlarmsResult`,
    label: '获取车辆警情列表',
    actionName: 'getTaskAlarms'
  },
  countTaskAlarmsResult: {
    value: `${api}alarm/${version}/countTaskAlarmsResult`,
    label: '获取车辆历史告警数量',
    actionName: 'countTaskAlarmsResult'
  },
  getTaskAlarmsResult: {
    value: `${api}alarm/${version}/getTaskAlarmsResult`,
    label: '车辆历史告警列表',
    actionName: 'getTaskAlarmsResult',
    logInfo: [
      {
        type: 5,
        code: 114201,
        parent: 114200,
        text: '查看告警信息',
        description: '查看重点车辆布控告警信息, 信息ID：<id>'
      },
      {
        type: 6,
        code: 114501,
        parent: 114500,
        text: '查看告警信息',
        description: '查看外来车辆布控告警信息, 信息ID：<id>'
      }
    ]
  },
  updateTaskAlarm: {
    value: `${api}alarm/${version}/updateTaskAlarm`,
    label: '布控任务启停',
    actionName: 'updateTaskAlarm',
    logInfo: [
      {
        type: 5,
        code: 114202,
        parent: 114200,
        text: '处理告警信息',
        description: '标记重点车辆布控告警为【<result>】, 信息ID：<id>'
      },
      {
        type: 6,
        code: 114502,
        parent: 114500,
        text: '处理告警信息',
        description: '标记外来车辆布控告警为【<result>】, 信息ID：<id>'
      },
      {
        type: 'noCapDetail',
        text: '处理提醒信息',
        code: 111202,
        parent: 111200,
        description: '标记无帽进入后厨布控告警为<result>，信息ID:<id>'
      },
      {
        type: 'epidemicMoniterDetail',
        text: '处理提醒信息',
        code: 111202,
        parent: 111200,
        description: '标记无帽进入后厨布控告警为<result>，信息ID:<id>'
      }
    ]
  },
  getTaskAlarmById: {
    value: `${api}alarm/${version}/getTaskAlarmById`,
    label: '根据ID获取车辆告警详情',
    actionName: 'getTaskAlarmById'
  }
}
