import config from '../config';
const { version, staticResource } = config;

export default {
  videoSurveillanceModule: {
    code: 103900,
    text: '视频监控',
  },
  enterVideoSurveillanceModule: {
    text: '进入视频监控界面',
    code: 103999,
    parent: 103900,
    moduleName: 'videoSurveillance'
  },
  videoAbstractModule: {
    code: 108000,
    text: '原始抓拍记录',
  },
  enterVideoAbstractModule: {
    text: '进入原始抓拍记录界面',
    code: 108099,
    parent: 108000,
    moduleName: 'videoAbstract'
  },
  screenShotModule: {
    text: '视频截图',
    code: 103905,
    parent: 103900
  },
  historyAddress: {
    value: `${staticResource}v2/video/queryHistoryAddress?Authorization=<token>`,
    label: '获取录像视频地址',
    actionName: 'queryHistoryAddress',
    logInfo: [
      {
        code: 103902,
        parent: 103900,
        text: '查看录像视频'
      }
    ]
  },
  videoCover: {
    value: `${staticResource}v2/video/getLatestCoverMap/<cid>?Authorization=<token>`,
    label: '获取设备最新的封面图',
    actionName: 'queryLatestCoverMap'
  },
  liveHlsAddress: {
    value: `${staticResource}v2/video/live.m3u8/<cid>?Authorization=<token>`,
    label: '获取HLS实时视频地址',
    actionName: 'liveHlsAddress',
    logInfo: [
      {
        code: 103901,
        parent: 103900,
        text: '实时视频'
      }
    ]
  },
  liveFlvAddress: {
    value: `${staticResource}v2/video/live.flv/<cid>?Authorization=<token>`,
    label: '获取FLV实时视频地址',
    actionName: 'liveFlvAddress',
    logInfo: [
      {
        code: 103901,
        parent: 103900,
        text: '实时视频'
      }
    ]
  },
  downloadAddress: {
    value: `${staticResource}v2/video/record/ts/<cid>/<fileName>?beginTime=<beginTime>&endTime=<endTime>&Authorization=<token>`,
    label: '获取设备录像TS下载地址',
    actionName: 'queryTSDownLoadAddress'
  },
  addPreset: {
    value: `${staticResource}${version}/preset/addPreset`,
    label: '添加预置点',
    actionName: 'addPreset'
  },
  deletePreset: {
    value: `${staticResource}${version}/preset/deletePreset`,
    label: '删除预置点',
    actionName: 'deletePreset'
  },
  queryPresets: {
    value: `${staticResource}${version}/preset/queryPresets`,
    label: '获取设备的预置点列表',
    actionName: 'queryPresets'
  },
  controlGBPTZ: {
    value: `${staticResource}v2/ptz/controlGBPTZ`,
    label: '国标控制云台转动（弃用）',
    actionName: 'controlCamera'
  },
  controlPTZ: {
    value: `${staticResource}v2/ptz/controlPTZ`,
    label: '控制云台转动（弃用）',
    actionName: 'controlCamera'
  },
  unifyControlPTZ: {
    value: `${staticResource}v2/ptz/unifyControlPTZ`,
    label: '统一控制云台转动',
    actionName: 'controlCamera'
  },
  controlFocus: {
    value: `${staticResource}v2/ptz/focus`,
    label: '视频聚焦',
    actionName: 'videoAbstract'
  },
  videoAbstract: {
    value: `${staticResource}v2/summary/video`,
    label: '视频摘要',
    actionName: 'videoAbstract'
  },
  localRecordTimeLine: {
    value: `${staticResource}${version}/localRecord/timeLine`,
    label: 'SDK 设备本地录像时间轴',
    actionName: 'localRecordTimeLine'
  },
  localRecordControl: {
    value: `${staticResource}${version}/localRecord/control`,
    label: 'SDK 设备本地录像控制',
    actionName: 'localRecordTimeLine'
  }
};
