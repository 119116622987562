import { httpRequest } from './http';
import video from './url/video';
import moment from 'moment';
import { message} from 'antd';
import md5 from 'js-md5';

@httpRequest
class VideoService {
  /**
   * @desc 获取历史视频
   * @params data = { cid, deviceName, startTime, endTime }
   *   cid 设备id
   *   deviceName 设备名称（非必传）
   *   startTime 秒级时间戳
   *   endTime 秒级时间戳
   */
  queryHistoryAddress({cid, deviceName, startTime, endTime}, errorLoading=true, isSaveLog=true) {
    BaseStore.action.setAction(video.historyAddress.actionName);
    // 记录日志
    const beginStringDate = moment.unix(startTime).format(Shared.format.dataTime);
    const endStringDate = moment.unix(endTime).format(Shared.format.dataTime);
    if (!deviceName) {
      deviceName = BaseStore.device.getDeviceByIdOrCid(cid).deviceName;
    }
    const logInfo = video.historyAddress.logInfo[0];
    isSaveLog && Service.logger.save({
      description: `查看点位【${deviceName}】 ${beginStringDate}到${endStringDate}的录像`,
      ...logInfo
    });
    const token = this.getToken();
    const url = video.historyAddress.value.replace('<token>', token);
    return this.$httpRequest({
      method: 'post',
      url,
      data: {
        // cid: '753373363',
        cid,
        endTime,
        beginTime: startTime,
        mediaType: 'hls'
      }
    }).then(res => {
      BaseStore.action.removeAction(video.historyAddress.actionName);
      let fragment = {};
      fragment.beginDate = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss');
      fragment.duration = endTime - startTime;
      fragment.fragments = [];
      const playlist = res.data;
      if (playlist.length === 0) {
        if(errorLoading) {
          fragment.fragments = [{
            begin: startTime,
            end: endTime,
            file: 'error'
          }];
        } else {
          message.warn('未获取到视频！')
        }
        return fragment;
      }

      let fragments = [];
      // 有历史视频处理断片时间
      if (playlist[0].beginTime !== startTime) {
        fragments.push({
          begin: 0,
          end: playlist[0].beginTime - startTime
        })
      }

      playlist.forEach((v, k) => {
        //begin----处理有视频部分
        fragments.push({
          begin: v.beginTime - startTime,
          end: v.endTime - startTime,
          file: v.url,
        });
        //end----处理有视频部分
        //begin----处理无视频部分
        const nextData = playlist[k + 1];
        if (nextData) {
          //处理存在的，视频断片数据
          if (nextData.beginTime < v.endTime) {
            console.error('数据有问题，下一个的视频开始时间小于上一个视频的结束时间');
          }
          //下一个的begin大于上一个的end，就是存在断片
          if (nextData.beginTime > v.endTime) {
            fragments.push({
              begin: v.endTime - startTime,
              end: nextData.beginTime - startTime,
            });
          }
        }
        //end----处理无视频部分
      });

      let lastItem = playlist[playlist.length - 1]
      if (lastItem.endTime < endTime) {
        fragments.push({
          begin: lastItem.endTime - startTime,
          end: endTime - startTime
        })
      }

      fragment.fragments = fragments;

      return fragment;
    }).catch(e => {
      BaseStore.action.removeAction(video.historyAddress.actionName);
      let fragment;
      if(errorLoading) {
        fragment = {};
        fragment.beginDate = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss');
        fragment.duration = endTime - startTime;
        fragment.fragments = [{
          begin: startTime,
          end: endTime,
          file: 'error'
        }];
      }
      return Promise.resolve(fragment);
    });
  }

  /**
   * @desc 获取视频封面
   */
  queryLatestCoverMap(cid) {
    BaseStore.action.setAction(video.videoCover.actionName);
    const token = this.getToken();
    const coverUrl = video.videoCover.value.replace('<cid>', cid).replace('<token>', token)
    return Promise.resolve(coverUrl);
  }

  /**
   * @desc 获取多个实时视频(同步代码)
   * @params deviceInfos [{
   *  cid, deviceName, deviceType, flvStream=undefined 
   * }]
   */ 
  queryRealTimeAddressMulti(deviceInfos) {
    const { qj, db } = Dict.map
    return Promise.all(deviceInfos.map(device => {
      let funcName = 'queryLiveHlsAddress';
      // 球机、单兵、fiv优先 播放fiv;
      if(device.deviceType == qj.value || device.deviceType == db.value || device.flvStream){
        funcName = 'queryLiveFlvAddress'
      }
      return this[funcName](device.cid, device.deviceName);
    })).then(results => {
      const fileDatas = deviceInfos.map((v,k) => {
        const ptzControl = v.deviceType === qj.value ? true : false;
        const file = results[k];
        // const file = '/api/staticResource/v1/video/live.m3u8/753373363';
        const data = {
          file,
          cid: v.cid,
          deviceName: v.deviceName,
          isLiving: true,
          ptzControl
        };
        return data;
      })
      return fileDatas;
    })
  }
  
  /**
   * @desc 获取实时视频 HLS
   */
  queryLiveHlsAddress(cid, deviceName) {
    // 记录日志
    const logInfo = video.liveHlsAddress.logInfo[0];
    Service.logger.save({
      description: `查看点位【${deviceName}】的实时视频`,
      ...logInfo
    });
    const token = this.getToken();
    return video.liveHlsAddress.value.replace('<cid>', cid).replace('<token>', token);
  }

  /**
   * @desc 获取实时视频 FLV
   */
  queryLiveFlvAddress(cid, deviceName) {
    // 记录日志
    const logInfo = video.liveFlvAddress.logInfo[0];
    Service.logger.save({
      description: `查看点位【${deviceName}】的实时视频`,
      ...logInfo
    });
    const token = this.getToken();
    return video.liveFlvAddress.value.replace('<cid>', cid).replace('<token>', token);
  }

  /**
   * @desc 下载历史视频
   */
  queryTSDownLoadAddress({cid, fileName, beginTime, endTime}) {
    // 记录日志
    const beginStringDate = moment.unix(beginTime).format(Shared.format.dataTime);
    const endStringDate = moment.unix(endTime).format(Shared.format.dataTime);
    Service.logger.save({
      description: `下载点位【${fileName}】 ${beginStringDate}到${endStringDate}的录像`,
      ...Service.url.request.downloadVideo
    });

    const token = this.getToken();
    const url = video.downloadAddress.value
                .replace('<cid>',cid)
                .replace('<fileName>', fileName)
                .replace('<beginTime>', beginTime)
                .replace('<endTime>', endTime)
                .replace('<token>', token)
    Utils.tagAToDownload({ url });
    return url;
  }

  /**
   * @desc 视频摘要
   * @param {object} data
   */
  queryVideoAbstract(data = {}){
    BaseStore.action.setAction(video.videoAbstract.actionName);
    return this.$httpRequest({
      url: video.videoAbstract.value,
      method: 'POST',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(video.videoAbstract.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(video.videoAbstract.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * 国标云台控制
   * @param {*} data 
   * @param {*} signKey 
   */
  controlGBPTZ({cid, cmd, control}) {
    return this.$httpRequest({
      url: video.controlGBPTZ.value,
      method: 'POST',
      data: {cid, cmd, control}
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  /**
   * 非国标云台控制
   * @param {*} data 
   * @param {*} signKey 
   */
  controlPTZ({cid, type, control}) {
    return this.$httpRequest({
      url: video.controlPTZ.value,
      method: 'POST',
      data: {cid, type, control}
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  unifyControlPTZ({cid, cmd, control}) {
    return this.$httpRequest({
      url: video.unifyControlPTZ.value,
      method: 'POST',
      data: {cid, cmd, control}
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
  /**
   * 聚焦控制
   * @param {*} data 
   * @param {*} signKey 
   */
  controlFocus({cid, cmd, control}) {
    return this.$httpRequest({
      url: video.controlFocus.value,
      method: 'POST',
      data: {cid, cmd, control}
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  /**
   * SDK 设备本地录像时间轴
    {
      "cid":"753373359",
      "beginTime":"1568649600",
      "endTime":"1568736000"
    }
   */
  localRecordTimeLine(data) {
    const mockData = {"code":0,"message":"ok","data":[{"from":"1571041260","to":"1571078730"},{"from":"1571078760","to":"1571127793"}]}
    // return Promise.resolve(mockData)
    return this.$httpRequest({
      url: video.localRecordTimeLine.value,
      method: 'POST',
      data
    }).then(res => res)
      .catch(e => Promise.reject(e));
  }

  /**
   * SDK 设备本地录像控制
    {
      "cid":"753373359",
      "beginTime":"1568649600",
      "endTime":"1568736000"
    }
   */
  localRecordControl({cid, startTime, endTime}, errorLoading=true) {
    const mockData = {
      beginDate: moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss'),
      duration: endTime - startTime,
      fragments: [{
        begin: startTime,
        end: endTime,
        file: moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
      }]
    }
    // return Promise.resolve(mockData)
    return this.$httpRequest({
      url: video.localRecordControl.value,
      method: 'POST',
      data: {
        cid, 
        beginTime: startTime, 
        endTime: endTime
      }
    }).then(res => {
      BaseStore.action.removeAction(video.historyAddress.actionName);
      let fragment = {};
      fragment.beginDate = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss');
      fragment.duration = endTime - startTime;
      fragment.fragments = [];
      const playlist = res.data;
      if (playlist.length === 0) {
        if(errorLoading) {
          fragment.fragments = [{
            begin: startTime,
            end: endTime,
            file: 'error'
          }];
        } else {
          message.warn('未获取到视频！')
        }
        return fragment;
      }

      let fragments = [];
      // 有历史视频处理断片时间
      if (playlist[0].beginTime !== startTime) {
        fragments.push({
          begin: 0,
          end: playlist[0].beginTime - startTime
        })
      }

      playlist.forEach((v, k) => {
        //begin----处理有视频部分
        fragments.push({
          begin: v.beginTime - startTime,
          end: v.endTime - startTime,
          file: v.flv,
        });
        //end----处理有视频部分
        //begin----处理无视频部分
        const nextData = playlist[k + 1];
        if (nextData) {
          //处理存在的，视频断片数据
          if (nextData.beginTime < v.endTime) {
            console.error('数据有问题，下一个的视频开始时间小于上一个视频的结束时间');
          }
          //下一个的begin大于上一个的end，就是存在断片
          if (nextData.beginTime > v.endTime) {
            fragments.push({
              begin: v.endTime - startTime,
              end: nextData.beginTime - startTime,
            });
          }
        }
        //end----处理无视频部分
      });

      let lastItem = playlist[playlist.length - 1]
      if (lastItem.endTime < endTime) {
        fragments.push({
          begin: lastItem.endTime - startTime,
          end: endTime - startTime
        })
      }

      fragment.fragments = fragments;

      return fragment;
    }).catch(e => {
      BaseStore.action.removeAction(video.historyAddress.actionName);
      let fragment;
      if(errorLoading) {
        fragment = {};
        fragment.beginDate = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss');
        fragment.duration = endTime - startTime;
        fragment.fragments = [{
          begin: startTime,
          end: endTime,
          file: 'error'
        }];
      }
      return Promise.resolve(fragment);
    });
  }

  /**
   * 生成签名用作接口校验
   */
  getSignature(data, signKey='lingmou') {
    let arr = [];
    for(var k in data) {
      arr.push(`${k}${data[k]}`)
    }
    let str = arr.sort().join('');
    str+=signKey;
    return md5(str)
  }

  getToken() {
    return Utils.getCache("token", "session")
  }

}
export default new VideoService();
