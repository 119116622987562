import { httpRequest } from './http';
import place from './url/place';

@httpRequest
class PlaceService {
  /**
   * @desc 查询场所内各场所类型人数统计
   * @param {Object} data
   */
  queryRelationVids(data) {
    BaseStore.action.setAction(place.countPersonByPlaceType.actionName);
    return this.$httpRequest({
      url: place.countPersonByPlaceType.value,
      data: data,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonByPlaceType.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonByPlaceType.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据场所ID查询详情
   * @param {Object} data
   */
  queryPlaceInfo(url, data) {
    BaseStore.action.setAction(place.placeInfo.actionName);
    return this.$httpRequest({
      url,
      method: 'POST',
      data: {
        id: data.id
      }
    })
      .then(res => {
        BaseStore.action.removeAction(place.placeInfo.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.placeInfo.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 小区绑定场所详情
   * @param {Object} data
   */
  placesExt(data) {
    BaseStore.action.setAction(place.placesExt.actionName);
    return this.$httpRequest({
      url: `${place.placesExt.value.replace('<id>', data.id)}`,
      method: 'POST',
      data: {
        id: data.id
      }
    })
      .then(res => {
        BaseStore.action.removeAction(place.placesExt.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.placesExt.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据场所ID查询下级场所列表
   * @param {Object} data
   */
  queryPlacesByParentId(data = {}) {
    BaseStore.action.setAction(place.queryPlacesByParentId.actionName);
    return this.$httpRequest({
      url: place.queryPlacesByParentId.value.replace('<parentId>', data.parentId),
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryPlacesByParentId.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryPlacesByParentId.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询行政区划信息
   * @param {Object} data
   */
  queryPlaces(data) {
    BaseStore.action.setAction(place.queryPlaces.actionName);
    return this.$httpRequest({
      url: place.queryPlaces.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryPlaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryPlaces.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取系统中所有省信息
   * @param {Object} data
   */
  queryProvinces(data) {
    BaseStore.action.setAction(place.queryProvinces.actionName);
    return this.$httpRequest({
      url: place.queryProvinces.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryProvinces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryProvinces.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取系统中所有省信息
   * @param {Object} data
   */
  queryMinPlaces(data) {
    BaseStore.action.setAction(place.queryMinPlaces.actionName);

    return this.$httpRequest({
      url: place.queryMinPlaces.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryMinPlaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryMinPlaces.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所内的设备
   * @param {Object} data
   */
  queryDevices(data) {
    BaseStore.action.setAction(place.queryDevices.actionName);
    return this.$httpRequest({
      url: place.queryDevices.value.replace('<placeId>', data.placeId),
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryDevices.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所内的设备数量
   * @param {Object} data
   */
  countDevices(data) {
    BaseStore.action.setAction(place.countDevices.actionName);
    return this.$httpRequest({
      url: place.countDevices.value.replace('<placeId>', data.placeId),
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countDevices.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countDevices.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所出入人、 人脸人体资源
   * @param {Object} data
   */
  countResources(url, data) {
    BaseStore.action.setAction(place.countResources.actionName);
    return this.$httpRequest({
      // url: place.countResources.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countResources.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countResources.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所机动车和非机动车资源
   * @param {Object} data
   */
  countVehiclesResources(url, data) {
    BaseStore.action.setAction(place.countVehiclesResources.actionName);
    return this.$httpRequest({
      // url: place.countVehiclesResources.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countVehiclesResources.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countVehiclesResources.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所人次统计， 展示场所人员出入规律
   * @param {Object} data
   */
  countPersonFrequency(url, data) {
    BaseStore.action.setAction(place.countPersonFrequency.actionName);
    return this.$httpRequest({
      // url: place.countPersonFrequency.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonFrequency.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonFrequency.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所人数统计， 展示场所人流量分布规律
   * @param {Object} data
   */
  countPersonNum(url, data) {
    BaseStore.action.setAction(place.countPersonNum.actionName);
    return this.$httpRequest({
      // url: place.countPersonNum.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonNum.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonNum.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询不同类型（ 常出入， 临时出入， 长期未出现） 人员数量
   * @param {Object} data
   */
  countTypeByPid(url, data) {
    BaseStore.action.setAction(place.countTypeByPid.actionName);
    return this.$httpRequest({
      // url: place.countTypeByPid.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countTypeByPid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countTypeByPid.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询不同类型（ 常出入， 临时出入， 长期未出现） 人员数量
   * @param {Object} data
   */
  countMinPlaces(url, data) {
    BaseStore.action.setAction(place.countMinPlaces.actionName);

    return this.$httpRequest({
      // url: place.countMinPlaces.value,
      url,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countMinPlaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countMinPlaces.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据设备id查询已有和新的场所列表
   * @param {Object} data
   */
  queryPOIByCenter(data) {
    BaseStore.action.setAction(place.queryPOIByCenter.actionName);

    return this.$httpRequest({
      url: place.queryPOIByCenter.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryPOIByCenter.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryPOIByCenter.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询场所人员数量
   * @param {} data
   */
  countPersonGroupByPid(data) {
    BaseStore.action.setAction(place.countPersonGroupByPid.actionName);

    return this.$httpRequest({
      url: place.countPersonGroupByPid.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonGroupByPid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonGroupByPid.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所人员数量
   * @param {} data
   */
  getPlacesByHasDevice() {
    BaseStore.action.setAction(place.getPlacesByHasDevice.actionName);
    return this.$httpRequest({
      url: place.getPlacesByHasDevice.value,
      method: 'POST'
    })
      .then(res => {
        BaseStore.action.removeAction(place.getPlacesByHasDevice.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.getPlacesByHasDevice.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询设备抓拍的人、 人脸、 人体资源
   * @param {} data
   */
  countDeviceResources(data) {
    BaseStore.action.setAction(place.countDeviceResources.actionName);

    return this.$httpRequest({
      url: place.countDeviceResources.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countDeviceResources.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countDeviceResources.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询设备抓拍的车辆资源
   * @param {} data
   */
  countVehiclesByCids(data) {
    BaseStore.action.setAction(place.countVehiclesByCids.actionName);

    return this.$httpRequest({
      url: place.countVehiclesByCids.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countVehiclesByCids.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countVehiclesByCids.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询设备抓拍的车辆资源
   * @param {} data
   */
  countDeviceCapPersons(data) {
    BaseStore.action.setAction(place.countDeviceCapPersons.actionName);

    return this.$httpRequest({
      url: place.countDeviceCapPersons.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countDeviceCapPersons.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countDeviceCapPersons.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 手动关联场所关系
   * @param {} data
   */
  activeAssociatedPlaces(data) {
    BaseStore.action.setAction(place.activeAssociatedPlaces.actionName);

    return this.$httpRequest({
      url: place.activeAssociatedPlaces.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.activeAssociatedPlaces.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.activeAssociatedPlaces.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询场所人员数量
   * @param {} data
   */
  inputAssistant(data) {
    BaseStore.action.setAction(place.inputAssistant.actionName);

    return this.$httpRequest({
      url: place.inputAssistant.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.inputAssistant.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.inputAssistant.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 手动关联设备场所关系
   * @param {} data
   */
  activeAssociatedDeviceToPlace = data => {
    BaseStore.action.setAction(place.activeAssociatedDeviceToPlace.actionName);

    return this.$httpRequest({
      url: place.activeAssociatedDeviceToPlace.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.activeAssociatedDeviceToPlace.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.activeAssociatedDeviceToPlace.actionName);
        return Promise.reject(e);
      });
  };

  /**
   * @desc 根据条件批量查询场所
   * @param {} data
   */
  getPlacesByConditions(data) {
    BaseStore.action.setAction(place.getPlacesByConditions.actionName);

    return this.$httpRequest({
      url: place.getPlacesByConditions.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.getPlacesByConditions.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.getPlacesByConditions.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 查询场所不同纬度的统计
   * @param {} data
   */
  countPerson(data) {
    BaseStore.action.setAction(place.countPerson.actionName);

    return this.$httpRequest({
      url: place.countPerson.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPerson.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPerson.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据条件批量查询场所
   * @param {} data
   */
  playPlaceTagsById(data, logData) {
    BaseStore.action.setAction(place.playPlaceTagsById.actionName);
    return this.$httpRequest({
      url: place.playPlaceTagsById.value,
      method: 'POST',
      data: data,
      logInfo: {
        description: `编辑场所【${logData.name}】标签为【${logData.placeTagsName}】`,
        ...place.playPlaceTagsById.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(place.playPlaceTagsById.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.playPlaceTagsById.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 根据经纬度或cid查询周边指定距离内的设备
   * @param {} data
   */
  queryDeviceByCenter(data) {
    BaseStore.action.setAction(place.queryDeviceByCenter.actionName);
    return this.$httpRequest({
      url: place.queryDeviceByCenter.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.queryDeviceByCenter.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.queryDeviceByCenter.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询不同类型（ 常出入， 临时出入， 长期未出现） 人员列表
   * @param {} data
   */
  getTypeByPid(url, data, logData) {
    BaseStore.action.setAction(place.getTypeByPid.actionName);
    return this.$httpRequest({
      //  url: place.getTypeByPid.value,
      url,
      method: 'POST',
      data: data,
      logInfo: {
        description: `查看场所【${logData.name}】的人员组成详情`,
        ...place.getTypeByPid.logInfo[0]
      }
    })
      .then(res => {
        BaseStore.action.removeAction(place.getTypeByPid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.getTypeByPid.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 获取场所配置
   * @param {} data
   */
  getPlaceTypeConfig(data) {
    BaseStore.action.setAction(place.getPlaceTypeConfig.actionName);
    return this.$httpRequest({
      url: place.getPlaceTypeConfig.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.getPlaceTypeConfig.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.getPlaceTypeConfig.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 取消设备关联的场所
   * @param {} data
   */
  cancelDevicePlaceRelation(data) {
    BaseStore.action.setAction(place.cancelDevicePlaceRelation.actionName);
    return this.$httpRequest({
      url: place.cancelDevicePlaceRelation.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.cancelDevicePlaceRelation.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.cancelDevicePlaceRelation.actionName);
        return Promise.reject(e);
      });
  }
  /**
   * @desc 查询设备抓拍图片统计情况
   * @param {} data
   */
  getDeviceStatisticByCid(data) {
    BaseStore.action.setAction(place.getDeviceStatisticByCid.actionName);
    return this.$httpRequest({
      url: place.getDeviceStatisticByCid.value,
      method: 'POST',
      data: data
    })
      .then(res => {
        BaseStore.action.removeAction(place.getDeviceStatisticByCid.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.getDeviceStatisticByCid.actionName);
        return Promise.reject(e);
      });
  }

  // 云社区
  /**
   * @desc 场所人次规律统计(出入规律)
   * @param {*} data
   */
  countPersonFrequencyToCMT(data) {
    BaseStore.action.setAction(place.countPersonFrequencyToCMT.value);
    return this.$httpRequest({
      url: place.countPersonFrequencyToCMT.value,
      method: 'post',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonFrequencyToCMT.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonFrequencyToCMT.actionName);
        return Promise.reject(e);
      });
  }

  /**
   * @desc 场所人流量分布规律
   * @param {*} data
   */
  countPersonNumToCMT(data) {
    BaseStore.action.setAction(place.countPersonNumToCMT.value);
    return this.$httpRequest({
      url: place.countPersonNumToCMT.value,
      method: 'post',
      data
    })
      .then(res => {
        BaseStore.action.removeAction(place.countPersonNumToCMT.actionName);
        return res;
      })
      .catch(e => {
        BaseStore.action.removeAction(place.countPersonNumToCMT.actionName);
        return Promise.reject(e);
      });
  }
}

export default new PlaceService();
