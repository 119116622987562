import config from '../config';
const { api, version } = config;

export default {
  massControl: {
    text: '群防群治',
    code: 108200,
  },
  enterMassControl: {
    text: '进入群防群治界面',
    code: 108299,
    parent: 108200,
    moduleName: 'massControl',
  },
  dealClue: {
    value: `${api}clue/${version}/dealClue`,
    label: '处理线索',
    actionName: 'dealClue',
    logInfo: [
      {
        code: 108202,
        parent: 108200,
        text: '处理线索'
      }
    ]
  },
  deleteClue: {
    value: `${api}clue/${version}/deleteClue`,
    label: '删除线索',
    actionName: 'deleteClue',
    logInfo: [
      {
        code: 108204,
        parent: 108200,
        text: '删除线索'
      }
    ]
  },
  exportClueByIds: {
    value: `${api}clue/${version}/exportClueByIds`,
    label: '导出excel',
    actionName: 'exportClueByIds'
  },
  queryClueList: {
    value: `${api}clue/${version}/queryClueList`,
    label: '查看线索列表',
    actionName: 'queryClueList'
  },
  queryCommentByClueIds: {
    value: `${api}clue/${version}/queryCommentByClueIds`,
    label: '查看线索对应的评论',
    actionName: 'queryCommentByClueIds'
  },
  saveClue: {
    value: `${api}clue/${version}/saveClue`,
    label: '上传线索',
    actionName: 'saveClue'
  },
  saveComment: {
    value: `${api}clue/${version}/saveComment`,
    label: '评论线索',
    actionName: 'saveComment',
    logInfo: [
      {
        code: 108203,
        parent: 108200,
        text: '编辑线索'
      }
    ]
  },
  updateComment: {
    value: `${api}clue/${version}/updateComment`,
    label: '更新评论',
    actionName: 'updateComment',
    logInfo: [
      {
        code: 108203,
        parent: 108200,
        text: '编辑线索'
      }
    ]
  },
  uploadBatch: {
    value: `${api}clue/${version}/uploadBatch`,
    label: '批量上传图片/视频',
    actionName: 'uploadBatch'
  },
  countClue: {
    value: `${api}clue/${version}/countClue`,
    label: '获取线索总数',
    actionName: 'countClue'
  }
};
